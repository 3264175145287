<template>
  <div
    style="
      width: 100%;
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      position: relative;
    "
    ref="boxDom"
  >
    <div style="display: flex; align-items: center; padding: 10px 10px 0 10px">
      <text style="margin-right: 20px; font-size: 13px; font-weight: 600"
        >旅游订单结算审核</text
      >
      <li style="flex: 1"></li>
      <el-button
        type="primary"
        icon="Search"
        link
        @click="
          $store.commit('openMenu', {
            path: 'LvYouDingDanSearch',
            title: '旅游订单详情',
            data: { id: props.data?.id },
            refresh: true,
          })
        "
        >查看订单详情</el-button
      >
      <el-button
        type="primary"
        icon="printer"
        link
        @click="state.showXcd = true"
        >打印行程单</el-button
      >
      <el-button
        type="primary"
        icon="printer"
        link
        @click="state.showFttzs = true"
        >打印发团通知书</el-button
      >
      <el-button
        type="primary"
        icon="printer"
        link
        @click="state.showJsd = true"
        >打印结算单</el-button
      >
      <!-- <el-button icon="printer" @click="state.showJsd = true"
        >打印结算单</el-button
      > -->
    </div>
    <div
      style="
        overflow: hidden;
        display: flex;
        box-shadow: 0 0 10px 1px #e2e2e2;
        margin: 10px;
        border-radius: 8px;
        padding: 10px;
      "
    >
      <div style="flex: 2; padding-right: 10px; overflow-y: auto">
        <div
          style="
            text-align: center;
            font-weight: 600;
            border-bottom: 1px solid #e2e2e2;
            padding-bottom: 10px;
          "
        >
          {{ state.model.Mc }}
        </div>
        <div style="display: flex; margin: 10px 0">
          <li style="flex: 1; font-weight: 600">团队信息{{ props.id }}</li>
          <li style="display: flex; align-items: center">
            <el-button
              type="info"
              :icon="Search"
              link
              @click="
                $store.commit('openMenu', {
                  path: 'LvYouDingDanDetail',
                  title: '旅游订单详情',
                  data: { id: props.data?.id },
                  refresh: true,
                })
              "
              >查看订单详情
            </el-button>
          </li>
        </div>
        <div style="border: 1px solid #e2e2e2; border-radius: 10px">
          <ul
            style="
              border-bottom: 1px solid #e2e2e2;
              display: flex;
              align-items: center;
              padding: 10px;
            "
          >
            <text style="font-weight: 600; font-size: 15px">{{
              state.model.Mc
            }}</text>
            <text style="margin: 0 10px; color: rgba(0, 0, 0, 0.8)">{{
              state.model.Bh
            }}</text>
            <c_status :model="state.item.Zt"></c_status>
          </ul>
          <ul style="display: flex; align-items: center; padding: 10px">
            <li style="display: flex; align-items: center">
              <text style="color: #a2a2a2">计调:</text>
              <text>
                {{
                  state.item.JdyXm +
                  (state.model.JdyBmMc ? " - " + state.model.JdyBmMc : "")
                }}
              </text>
            </li>
            <li style="display: flex; align-items: center; margin: 0 20px">
              <text style="color: #a2a2a2">日期:</text>
              <text>
                {{ state.item.Ftsj + "至" + state.item.Htsj }}
              </text>
            </li>
            <li style="display: flex; align-items: center">
              <text style="color: #a2a2a2">天数:</text>
              <text>
                {{ state.model.Ts + "天" + state.model.Ys + "晚" }}
              </text>
            </li>
            <li style="display: flex; align-items: center">
              <text style="color: #a2a2a2">人数:</text>
              <text>
                {{ state.model.Zrs }}
              </text>
            </li>
          </ul>
        </div>
        <div class="jsdetail_title">费用明细</div>
        <table style="text-align: right">
          <tr>
            <th>销售金额</th>
            <th>已收</th>
            <th>未收</th>
            <th>采购成本</th>
            <th>导游补助</th>
            <th>成本合计</th>
            <th>利润</th>
          </tr>
          <tr>
            <td>{{ $priceFormat(state.model.Zje || 0) }}</td>
            <td>{{ $priceFormat(state.model.Ysje || 0) }}</td>
            <td>{{ $priceFormat(state.model.Qkje || 0) }}</td>
            <td>{{ $priceFormat(state.model.Cgcb || 0) }}</td>
            <td>{{ $priceFormat(state.model.Dbhj || 0) }}</td>
            <td>
              {{ $priceFormat(state.model.Cgcb + state.model.Dbhj || 0) }}
            </td>
            <td>
              {{
                $priceFormat(
                  state.model.Zje - state.model.Cgcb - state.model.Dbhj || 0
                )
              }}
            </td>
          </tr>
        </table>
        <div class="jsdetail_title">采购信息</div>
        <table style="text-align: left">
          <tr>
            <th>类别</th>
            <th>采购项</th>
            <th>供应商</th>
            <th>合计</th>
            <th>预计现付</th>
            <th>实际现付</th>
            <th>已付</th>
            <th>未付</th>
          </tr>
          <tr v-for="item in state.cgs" :key="item">
            <td>{{ item.LbMc }}</td>
            <td style="flex: 2">
              <ul v-for="mx in item.Mxs" :key="mx">
                {{
                  mx.Lx +
                  " - " +
                  mx.Mc +
                  " " +
                  $priceFormat(mx.Dj || 0) +
                  " * " +
                  mx.Sl1 +
                  (mx.Sl2 > 0 ? " * " + mx.Sl2 : "") +
                  " = " +
                  $priceFormat(mx.Zj || 0) +
                  " 元"
                }}
              </ul>
            </td>
            <td>{{ item.GysMc }}</td>
            <td>{{ $priceFormat(item.Zje || 0) }}</td>
            <td>{{ $priceFormat(item.Yjxf || 0) }}</td>
            <td>{{ $priceFormat(item.Sjxf || 0) }}</td>
            <td>{{ $priceFormat(item.Yf || 0) }}</td>
            <td>{{ $priceFormat(item.Qk || 0) }}</td>
          </tr>
          <tr>
            <td colspan="3" style="text-align: right">合计：</td>
            <td>{{ $priceFormat(state.model.Cgcb) }}</td>
            <td>{{ $priceFormat(state.model.Cgyjxf) }}</td>
            <td>{{ $priceFormat(state.model.Cgsjxf) }}</td>
            <td>{{ $priceFormat(state.model.Cgyf) }}</td>
            <td>{{ $priceFormat(state.model.Cgqk) }}</td>
          </tr>
        </table>
        <div class="jsdetail_title">导游信息</div>
        <table style="text-align: left">
          <tr>
            <th style="widows: 40%">带团日期</th>
            <th>导游姓名</th>
            <th>带团说明</th>
            <th style="text-align: center">补助是否现发</th>
            <th style="text-align: right">补助金额</th>
          </tr>
          <tr v-for="item in state.dys" :key="item">
            <td>{{ item.Dtksrq + " ~ " + item.Dtjsrq }}</td>
            <td>
              {{ item.Xm }}
            </td>
            <td>{{ item.Sm || "-" }}</td>
            <td style="text-align: center">{{ item.IsXf ? "是" : "否" }}</td>
            <td style="text-align: right">{{ $priceFormat(item.Bzje) }}</td>
          </tr>
        </table>
        <div class="jsdetail_title">借还款信息</div>
        <div style="border: 1px solid #e2e2e2">
          <ul style="display: flex">
            <li
              style="
                background-color: #f5f7fa;
                font-weight: 600;
                text-align: right;
                width: 150px;
                padding: 10px;
                border-right: 1px solid #e2e2e2;
              "
            >
              借款金额：
            </li>
            <li style="flex: 1.5; padding: 10px">
              {{ $priceFormat(state.model.Jkje) }}
            </li>
            <li
              style="
                background-color: #f5f7fa;
                font-weight: 600;
                text-align: right;
                width: 150px;
                padding: 10px;
                border-right: 1px solid #e2e2e2;
              "
            >
              导游现收：
            </li>
            <li style="flex: 1.5; padding: 10px">
              {{ $priceFormat(state.model.Dyxs) }}
            </li>
            <li
              style="
                background-color: #f5f7fa;
                font-weight: 600;
                text-align: right;
                width: 150px;
                padding: 10px;
                border-right: 1px solid #e2e2e2;
              "
            >
              导游现付：
            </li>
            <li style="flex: 1.5; padding: 10px">
              {{ $priceFormat(state.model.Dyxf) }}
            </li>
            <li
              style="
                background-color: #f5f7fa;
                font-weight: 600;
                text-align: right;
                width: 150px;
                padding: 10px;
                border-right: 1px solid #e2e2e2;
              "
            >
              导补现发：
            </li>
            <li style="flex: 1.5; padding: 10px">
              {{ $priceFormat(state.model.Bzxf) }}
            </li>
            <li
              style="
                background-color: #f5f7fa;
                font-weight: 600;
                text-align: right;
                width: 150px;
                padding: 10px;
                border-right: 1px solid #e2e2e2;
              "
            >
              导游应还：
            </li>
            <li style="flex: 1.5; padding: 10px">
              {{
                $priceFormat(
                  state.model.Jkje +
                    state.model.Dyxs -
                    state.model.Dyxf -
                    state.model.Bzxf
                )
              }}
            </li>
          </ul>
          <ul
            style="
              display: flex;
              align-items: center;
              overflow: hidden;
              border-top: 1px solid #e2e2e2;
            "
          >
            <li
              style="
                background-color: #f5f7fa;
                font-weight: 600;
                text-align: right;
                width: 150px;
                padding: 10px;
                border-right: 1px solid #e2e2e2;
              "
            >
              借款说明：
            </li>
            <li style="flex: 1; padding: 10px">
              {{ state.model.Jksm || "-" }}
            </li>
          </ul>
          <ul
            style="
              display: flex;
              align-items: center;
              overflow: hidden;
              border-top: 1px solid #e2e2e2;
            "
          >
            <li
              style="
                background-color: #f5f7fa;
                font-weight: 600;
                text-align: right;
                width: 150px;
                padding: 10px;
                border-right: 1px solid #e2e2e2;
              "
            >
              领用物资：
            </li>
            <li style="flex: 1; padding: 10px">
              {{ state.model.FtLywz || "-" }}
            </li>
          </ul>
          <ul
            style="
              display: flex;
              align-items: center;
              overflow: hidden;
              border-top: 1px solid #e2e2e2;
            "
          >
            <li
              style="
                background-color: #f5f7fa;
                font-weight: 600;
                text-align: right;
                width: 150px;
                padding: 10px;
                border-right: 1px solid #e2e2e2;
              "
            >
              其他注意事项：
            </li>
            <li style="flex: 1; padding: 10px">
              {{ state.model.FtZysx || "-" }}
            </li>
          </ul>
        </div>
      </div>
      <div style="flex: 1; margin-left: 10px; padding-right: 10px">
        <ul style="font-weight: 600; margin-bottom: 10px">
          金额明细
        </ul>
        <div
          style="
            white-space: pre-wrap;
            border: 1px solid #e2e2e2;
            border-radius: 10px;
            padding: 7px;
            min-height: 100px;
          "
        >
          <ul style="padding: 10px 0">
            团队总产值 = 销售金额<text
              style="color: #f00; font-weight: 600; margin-left: 5px"
              >{{ $priceFormat(state.model.Zje) + "元" }}</text
            >
          </ul>
          <ul style="padding: 10px 0">
            团队总成本 = 团队采购成本{{
              $priceFormat(state.model.Cgcb)
            }}
            + 导游补助({{
              $priceFormat(state.model.Dbhj)
            }}) =
            <text style="color: #f00; font-weight: 600; margin-left: 5px">{{
              $priceFormat(state.model.Cgcb + state.model.Dbhj) + "元"
            }}</text>
          </ul>
          <ul style="padding: 10px 0">
            团队毛利 = 团队总产值{{
              $priceFormat(state.model.Zje)
            }}
            - 团队总成本({{
              $priceFormat(state.model.Cgcb + state.model.Dbhj)
            }}) =
            <text style="color: #f00; font-weight: 600; margin-left: 5px">{{
              $priceFormat(
                state.model.Zje - state.model.Cgcb - state.model.Dbhj
              ) + "元"
            }}</text>
          </ul>
          <ul style="padding: 10px 0">
            团队毛利率：<text style="color: #f00; font-weight: 600">{{
              state.model.Cgcb + state.model.Dbhj == 0
                ? "0%"
                : $numToPercentFormat(
                    $priceFormat(
                      (state.model.Zje - state.model.Cgcb - state.model.Dbhj) /
                        state.model.Zje,
                      4
                    )
                  )
            }}</text>
            人均毛利：<text style="color: #f00; font-weight: 600">{{
              state.model.Zrs == 0
                ? "0"
                : $priceFormat(
                    (state.model.Zje - state.model.Cgcb - state.model.Dbhj) /
                      state.model.Zrs
                  )
            }}</text>
          </ul>
        </div>
        <ul style="font-weight: 600; margin: 10px 0">
          审核说明
        </ul>
        <el-input
          v-model="state.bz"
          type="textarea"
          :autosize="{ minRows: 4 }"
        ></el-input>
        <ul style="margin-top: 10px">
          <el-button type="primary" @click="onSubmit(true)">
            审核通过
          </el-button>
          <el-button type="danger" @click="onSubmit(false)">
            审核不通过
          </el-button>
        </ul>
      </div>
    </div>
  </div>
  <transition name="el-zoom-in-center">
    <c_popup
      v-if="state.showFttzs"
      title="发团通知书"
      @close="state.showFttzs = false"
    >
      <c_LvYouDingDanFttzs
        v-if="state.showFttzs"
        :id="props.data?.id"
      ></c_LvYouDingDanFttzs>
    </c_popup>
  </transition>
  <transition name="el-zoom-in-center">
    <c_popup v-if="state.showXcd" title="行程单" @close="state.showXcd = false">
      <c_LvYouDingDanXcd
        v-if="state.showXcd"
        :id="props.data?.id"
      ></c_LvYouDingDanXcd>
    </c_popup>
  </transition>
  <transition name="el-zoom-in-center">
    <c_popup v-if="state.showJsd" title="结算单" @close="state.showJsd = false">
      <c_LvYouDingDanJsd
        v-if="state.showJsd"
        :id="props.data?.id"
      ></c_LvYouDingDanJsd>
    </c_popup>
  </transition>
</template>

<script>
import { onMounted, reactive } from "vue";
import { lvyoudingdan_detail, lvyoudingdan_jssh } from "@/http/api";
import store from "@/store";
import { ElMessageBox } from "element-plus";
export default {
  props: {
    data: Object,
  },
  setup(props) {
    const state = reactive({
      model: {},
      item: {},
      tabsName: "mx",
      ftModel: { Jkje: 0 }, //发团操作对象
      bzModel: { Dyxs: 0 }, //报账操作对象
    });
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      lvyoudingdan_detail(props.data?.id).then((res) => {
        if (res.status == 200) {
          const { model, item, mxs, dys, cgs } = res.data;
          state.model = model;
          state.item = item;
          state.mxs = mxs;
          state.dys = dys;
          state.cgs = cgs;
        }
      });
    };

    const onSubmit = (result) => {
      ElMessageBox.confirm(
        `您确定${
          result
            ? '<strong style="color:#080">同意</strong>'
            : '<strong style="color:#f00">拒绝</strong>'
        }此结算申请吗？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          dangerouslyUseHTMLString: true,
          type: "warning",
        }
      ).then(() => {
        lvyoudingdan_jssh(props.data?.id, {
          Result: result,
          Bz: state.bz,
        }).then((res) => {
          if (res.status == 200) {
            ElMessageBox.alert(res.data, "提示", {
              dangerouslyUseHTMLString: true,
              type: "success",
              callback: () => {
                store.commit("onCloseCurrent");
                store.commit("openMenu", {
                  path: "LvYouDingDanJieSuanList",
                  title: "结算审核",
                  refresh: true,
                });
              },
            });
          }
        });
      });
    };

    return {
      state,
      onLoad,
      props,
      onSubmit,
    };
  },
};
</script>
<style lang="less" scoped>
.modelBox {
  & > ul {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 20px;

    & > li {
      display: flex;
      align-items: center;
      flex: 1;
      & > text:first-child {
        color: #a2a2a2;
      }
    }
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}

th {
  border-top-left-radius: 10px;
  background-color: #f5f7fa;
}

td,
th {
  border: 1px solid #e2e2e2;
  padding: 10px;
}

.jsdetail_title {
  font-weight: 600;
  margin: 10px 0;
}

.jsdetail_tabBox {
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  width: 100%;
  overflow: hidden;

  & > ul {
    display: flex;
    align-items: center;
    & > li {
      flex: 1;
      padding: 10px;
      overflow: hidden;
    }

    & > li:not(:last-child) {
      border-right: 1px solid #e2e2e2;
    }
  }

  & > ul:first-child {
    font-weight: 600;
    background-color: #f7f7f7;
  }

  & > ul:not(:last-child) {
    border-bottom: 1px solid #e2e2e2;
  }
}
</style>
<style lang="less">
.lydd_bzBox {
  .el-dialog__body {
    padding: 0 !important;
  }

  .el-form-item {
    margin-bottom: 10px !important;
  }
}
</style>
