<template>
  <div class="detailBox">
    <ul class="detailContent">
      <el-form
        :model="state.model"
        label-width="100px"
        :scroll-to-error="true"
        ref="formDom"
        inline
        style="position: relative"
      >
        <el-form-item
          label="状态"
          prop="IsQy"
          :rules="[
            { required: true, message: '请选择角色状态', trigger: 'blur' },
          ]"
        >
          <el-switch
            v-model="state.model.IsQy"
            inline-prompt
            active-text="可售"
            inactive-text="停用"
          />
        </el-form-item>
        <el-form-item
          label="商品品类"
          prop="PlId"
          :rules="[
            { required: true, message: '请选择商品品类', trigger: 'blur' },
          ]"
          style="width: 30%"
        >
          <el-select
            v-model="state.model.PlId"
            placeholder="--请选择商品品类--"
            :filter-method="onPlFilter"
            :disabled="!Array.isArray(state.plData)"
          >
            <el-option
              v-for="item in state.plData || []"
              :key="item.Id"
              :label="item.Mc"
              :value="item.Id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="供应商"
          prop="GysId"
          :rules="[
            { required: true, message: '请选择供应商', trigger: 'blur' },
          ]"
          style="flex: 1; margin-right: 0"
        >
          <el-select
            v-model="state.model.GysId"
            placeholder="--请选择供应商--"
            value-key="Mc"
            :disabled="!Array.isArray(state.gysData)"
          >
            <el-option
              v-for="item in state.gysData"
              :key="item.Id"
              :label="item.Mc"
              :value="item.Id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="商品名称"
          prop="Mc"
          :rules="[
            { required: true, message: '请输入商品名称', trigger: 'blur' },
          ]"
          style="width: 100%"
        >
          <el-input v-model="state.model.Mc" /> </el-form-item
        ><el-form-item
          label="联系电话"
          prop="Dh"
          :rules="[
            { required: true, message: '请输入联系电话', trigger: 'blur' },
          ]"
          style="width: 30%; margin-right: 0"
        >
          <el-input v-model="state.model.Dh" />
        </el-form-item>
        <el-form-item label="地址" style="width: 70%; margin-right: 0">
          <el-input v-model="state.model.Dz" />
        </el-form-item>
        <el-form-item label="商品简介" style="width: 100%">
          <el-input
            type="textarea"
            v-model="state.model.Jj"
            :autosize="{ minRows: 2 }"
          />
        </el-form-item>
      </el-form>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref } from "vue";
import {
  shangpin_add,
  shangpin_edit,
  shangpin_detail,
  zidian_select,
  gongyingshang_select,
} from "@/http/api";

export default {
  props: {
    id: Number,
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const formDom = ref(null);
    const uploadDom = ref(null);
    const state = reactive({
      model: {
        IsQy: true,
        Tcs: [],
        Fm: "",
        Tps: [],
      },
      pics: [],
      delPics: [],
    });
    onMounted(() => {
      loadPl();
      loadGys();
      loadMdd();

      if (props.id) {
        shangpin_detail(props.id).then((res) => {
          if (res.status == 200) {
            state.model = res.data;
          }
        });
      }
    });

    //加载品类下拉数据
    const loadPl = () => {
      zidian_select("商品品类").then((res) => {
        if (res.status == 200) {
          state.plData = res.data;
          state.plSource = res.data;
        }
      });
    };

    //商品品类下拉框自定义筛选
    const onPlFilter = (query) => {
      state.plData = state.plSource.filter(
        (w) =>
          w.Py?.toUpperCase().includes(query.toUpperCase()) ||
          w.Sp?.toUpperCase().includes(query.toUpperCase()) ||
          w.Mc?.includes(query.toUpperCase())
      );
    };

    //加载供应商下拉数据
    const loadGys = () => {
      gongyingshang_select().then((res) => {
        if (res.status == 200) state.gysData = res.data;
      });
    };

    //加载品类下拉数据
    const loadMdd = () => {
      zidian_select("目的地").then((res) => {
        if (res.status == 200) {
          state.mddData = res.data;
          state.mddSource = res.data;
        }
      });
    };

    //下拉框自定义筛选
    const onMddFilter = (query) => {
      state.mddData = state.mddSource.filter(
        (w) =>
          w.Py?.toUpperCase().includes(query.toUpperCase()) ||
          w.Sp?.toUpperCase().includes(query.toUpperCase()) ||
          w.Mc?.includes(query.toUpperCase())
      );
    };

    const onSubmit = () => {
      formDom.value.validate((valid) => {
        if (!valid) return false;
        console.log(state.model);

        if (props.id) {
          shangpin_edit(state.model).then((res) => {
            proxy.$post_response(res, emit);
          });
        } else {
          shangpin_add(state.model).then((res) => {
            proxy.$post_response(res, emit);
          });
        }
      });
    };

    return {
      state,
      formDom,
      onSubmit,
      props,
      onPlFilter,
      onMddFilter,
      uploadDom,
    };
  },
};
</script>
<style scoped></style>
