<template>
  <div
    style="
      width: 100%;
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      padding: 10px;
    "
    ref="boxDom"
  >
    <div
      style="
        box-shadow: 0 0 10px 0 #e2e2e2;
        padding: 30px 10px;
        border-radius: 7px;
        line-height: 18px;
      "
    >
      <ul style="display: flex; align-items: center; font-size: 16px">
        <li style="flex: 1; color: #a2a2a2; text-align: center">
          共：<text style="font-weight: 600; font-size: 22px; color: #000">{{
            state.model.Dds
          }}</text
          >笔
        </li>
        <li
          style="
            flex: 1.5;
            color: #a2a2a2;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          合计应付：
          <c_money
            :value="state.model.Zje"
            :size="24"
            style="font-weight: 600"
          ></c_money>
        </li>
        <c_status :model="state.item.Zt"></c_status>
        <li style="flex: 1"></li>
        <li v-if="state.model.Zt == 0 && state.item.IsYwzg">
          <el-button type="primary" @click="onCheck(1, true)">
            审核通过
          </el-button>
          <el-button type="danger" @click="onCheck(1, false)">
            审核不通过
          </el-button>
        </li>
        <li v-if="state.model.Zt == 2 && state.item.IsYw">
          <el-button type="primary" @click="onCheck(2, true)">
            审核通过
          </el-button>
          <el-button type="danger" @click="onCheck(2, false)">
            审核不通过
          </el-button>
        </li>
        <li v-else-if="state.model.Zt == 4 && state.item.IsCw">
          <el-button type="primary" @click="onCheck(3, true)">
            审核通过
          </el-button>
          <el-button type="danger" @click="onCheck(3, false)">
            审核不通过
          </el-button>
        </li>
        <li v-else-if="state.model.Zt == 6">
          <el-button type="primary" @click="onPay">确认付款</el-button>
        </li>
      </ul>
    </div>
    <el-descriptions :column="3" border style="margin: 10px 0">
      <el-descriptions-item
        label="订单编号/状态"
        label-align="right"
        class-name="lydd_model_item"
      >
        <li class="flex-row ai-center">
          <text>{{ state.model.Bh + "/" }}</text>
          <c_status :model="state.item.Zt"></c_status>
        </li>
      </el-descriptions-item>
      <el-descriptions-item
        label="申请人"
        label-align="right"
        class-name="lydd_model_item"
      >
        {{ state.model.CjrXm || "-" }}
      </el-descriptions-item>
      <el-descriptions-item
        label="申请时间"
        label-align="right"
        class-name="lydd_model_item"
      >
        {{ state.model.Cjsj || "-" }}
      </el-descriptions-item>
      <el-descriptions-item
        label="类型"
        label-align="right"
        class-name="lydd_model_item"
      >
        <c_status :model="state.item.Lx"></c_status>
      </el-descriptions-item>
      <el-descriptions-item
        label="付款方式"
        label-align="right"
        class-name="lydd_model_item"
      >
        <c_status :model="state.item.Fkfs"></c_status>
      </el-descriptions-item>
      <el-descriptions-item
        label="供应商"
        label-align="right"
        class-name="lydd_model_item"
      >
        {{ state.item.GysMc || "-" }}
      </el-descriptions-item>
      <el-descriptions-item
        label="开户行"
        label-align="right"
        class-name="lydd_model_item"
      >
        {{ state.model.Dzyh }}
      </el-descriptions-item>
      <el-descriptions-item
        label="卡号"
        label-align="right"
        class-name="lydd_model_item"
      >
        {{ state.model.Dzkh }}
      </el-descriptions-item>
      <el-descriptions-item
        label="收款人"
        label-align="right"
        class-name="lydd_model_item"
      >
        {{ state.model.Skr }}
      </el-descriptions-item>
      <el-descriptions-item
        label="业务审核人"
        label-align="right"
        class-name="lydd_model_item"
      >
        {{ state.model.YwrXm }}
      </el-descriptions-item>
      <el-descriptions-item
        label="业务审核状态"
        label-align="right"
        class-name="lydd_model_item"
      >
        <text v-if="state.model.Ywzt == null" style="color: #a2a2a2">
          未审核
        </text>
        <template v-else>
          <text v-if="state.model.Ywzt == true" style="color: #1e90ff">
            通过
          </text>
          <text v-else-if="state.model.Ywzt == false" style="color: #f00">
            不通过
          </text>
          <text style="color: #a2a2a2">
            {{ state.model.Ywsj }}
          </text>
        </template>
      </el-descriptions-item>
      <el-descriptions-item
        label="业务审核备注"
        label-align="right"
        class-name="lydd_model_item"
      >
        {{ state.model.Ywbz || "-" }}
      </el-descriptions-item>
      <el-descriptions-item
        label="财务审核人"
        label-align="right"
        class-name="lydd_model_item"
      >
        {{ state.model.CwrXm }}
      </el-descriptions-item>
      <el-descriptions-item
        label="财务审核状态"
        label-align="right"
        class-name="lydd_model_item"
      >
        <text v-if="state.model.Cwzt == null" style="color: #a2a2a2">
          未审核
        </text>
        <template v-else>
          <text v-if="state.model.Cwzt == true" style="color: #1e90ff">
            通过
          </text>
          <text v-else-if="state.model.Cwzt == false" style="color: #f00">
            不通过
          </text>
          <text style="color: #a2a2a2">
            {{ state.model.Cwsj }}
          </text>
        </template>
      </el-descriptions-item>
      <el-descriptions-item
        label="财务审核备注"
        label-align="right"
        class-name="lydd_model_item"
      >
        {{ state.model.Cwbz || "-" }}
      </el-descriptions-item>
      <el-descriptions-item
        label="付款人"
        label-align="right"
        class-name="lydd_model_item"
      >
        {{ state.model.FkrXm || "-" }}
      </el-descriptions-item>
      <el-descriptions-item
        label="付款时间"
        label-align="right"
        class-name="lydd_model_item"
        :span="2"
      >
        {{ state.model.Fksj || "-" }}
      </el-descriptions-item>
      <el-descriptions-item
        label="备注"
        label-align="right"
        class-name="lydd_model_item"
      >
        {{ state.model.Bz || "-" }}
      </el-descriptions-item>
    </el-descriptions>
    <div>
      <el-table
        :data="state.spdds"
        v-loading="!Array.isArray(state.spdds)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
        v-if="state.model.Lx == 1"
      >
        <el-table-column
          fixed
          label="#"
          width="50"
          type="index"
          align="center"
        />
        <el-table-column label="商品信息" min-width="200">
          <template #default="{ row }">
            <div>
              <ul>
                <text style="color: #000">{{ row.SpMc }}</text>
                <text style="color: #a2a2a2">[{{ row.GysMc }}]</text>
              </ul>
              <ul style="font-size: 12px; line-height: 12px; color: #a2a2a2">
                {{
                  row.SpPlMc +
                  "  " +
                  row.Syrq +
                  "  " +
                  row.SpTcMc +
                  "  ￥" +
                  row.Xsdj +
                  "/" +
                  row.SpTcDw +
                  " x " +
                  row.Sl
                }}
              </ul>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="订单信息" min-width="200">
          <template #default="{ row }">
            <div>
              <ul>
                <text style="color: #000">{{ row.Dh }}</text>
                <text style="color: #a2a2a2">[{{ row.KhMc }}]</text>
              </ul>
              <ul style="font-size: 12px; line-height: 12px; color: #a2a2a2">
                {{
                  row.KhLxr + "  " + row.KhSjhm + "  " + row.Xdsj
                }}
              </ul>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="Jszj"
          label="结算金额"
          :formatter="$tab_MoneyFormat"
        />
        <el-table-column
          prop="Yfje"
          label="已付金额"
          :formatter="$tab_MoneyFormat"
        />
        <el-table-column
          prop="Fkje"
          label="本次付款"
          align="right"
          :formatter="$tab_MoneyFormat"
        />
      </el-table>
      <el-table
        :data="state.lycgs"
        v-loading="!Array.isArray(state.lycgs)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
        v-if="state.model.Lx == 2"
      >
        <el-table-column
          fixed
          label="#"
          width="50"
          type="index"
          align="center"
        />
        <el-table-column label="采购信息" min-width="200">
          <template #default="{ row }">
            <div>
              <ul>
                <text style="color: #000">{{ row.Bh }}</text>
                <text style="color: #a2a2a2"
                  >[{{ row.Lb1Mc + " - " + row.Lb2Mc }}]</text
                >
              </ul>
              <ul
                v-for="mx in row.Mxs"
                :key="mx"
                style="
                  font-size: 12px;
                  line-height: 12px;
                  color: #a2a2a2;
                  white-space: nowrap;
                "
              >
                {{
                  mx.Lx +
                  " - " +
                  mx.Mc +
                  "  " +
                  $priceFormat(mx.Dj) +
                  " * " +
                  mx.Sl1
                }}
                <text v-if="mx.Sl2">{{ " * " + mx.Sl2 }}</text>
                <text>{{ " = " + $priceFormat(mx.Zj) + " 元 " }}</text>
              </ul>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="GysMc" label="供应商" width="120">
        </el-table-column>
        <el-table-column label="订单信息" min-width="200">
          <template #default="{ row }">
            <div>
              <ul style="white-space: nowrap">
                <text :style="{ color: row.DdZt.Color }"
                  >[{{ row.DdZt.Text }}]</text
                >
                <text style="color: #000">{{ row.DdMc }}</text>
                <text style="color: #a2a2a2">[{{ row.DdBh }}]</text>
              </ul>
              <ul
                style="
                  color: #a2a2a2;
                  white-space: nowrap;
                  font-size: 12px;
                  line-height: 12px;
                "
              >
                <text> [{{ row.DdLx.Text }}] </text>
                <text>
                  {{ row.DdTs + "天" }}
                </text>
                <text v-if="row.DdYs">
                  {{ row.DdYs + "晚" }}
                </text>
                <text>
                  {{ row.DdFtsj + " ~ " + row.DdHtsj }}
                </text>
              </ul>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="采购金额"
          label="预计/实际现付"
          width="120"
          :formatter="$tab_MoneyFormat"
          align="right"
        >
          <template #default="{ row }">
            <div style="color: #ffa500; text-align: right">
              <ul>
                {{
                  $priceFormat(row.Yjxf)
                }}
              </ul>
              <ul>
                {{
                  $priceFormat(row.Sjxf)
                }}
              </ul>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="已付/未付"
          width="120"
          :formatter="$tab_MoneyFormat"
          align="right"
        >
          <template #default="{ row }">
            <div style="color: #ffa500; text-align: right">
              <ul>
                {{
                  $priceFormat(row.Yf)
                }}
              </ul>
              <ul>
                {{
                  $priceFormat(row.Qk)
                }}
              </ul>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="Fkje"
          label="本次付款"
          align="right"
          width="130"
          :formatter="$tab_MoneyFormat"
        />
      </el-table>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import {
  duizhangdan_detail,
  duizhangdan_getddmxs,
  duizhangdan_check,
  duizhangdan_pay,
} from "@/http/api";
import http from "@/http";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  props: {
    data: Object,
  },
  setup(props) {
    const tabsDom = ref(null);
    const boxDom = ref(null);
    const state = reactive({
      model: {},
      item: {},
      mxData: [],
      lycgs: [],
      spdds: [],
    });
    onMounted(() => {
      if (props.data?.id) {
        onLoadDetail();
      }
    });

    const onLoadDetail = () => {
      duizhangdan_detail(props.data?.id).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          const { model, item, lycgs, spdds } = res.data;
          state.model = model;
          state.item = item;
          state.lycgs = lycgs;
          state.spdds = spdds;
        }
      });

      duizhangdan_getddmxs(props.data?.id).then((res) => {
        if (res.status == 200) state.mxData = res.data;
      });
    };

    const onCheck = (type, result) => {
      ElMessageBox.confirm(
        `您确定${
          result
            ? '<strong style="color:#080">同意</strong>'
            : '<strong style="color:#f00">拒绝</strong>'
        }此对账申请吗？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          dangerouslyUseHTMLString: true,
          type: "warning",
        }
      ).then(() => {
        if (result == false) {
          ElMessageBox.prompt("请输入审核备注", "审核不通过", {
            confirmButtonText: "提交",
            cancelButtonText: "取消",
            inputType: "textarea",
          }).then(({ value }) => {
            duizhangdan_check(props.data?.id, {
              Type: type,
              Result: result,
              Bz: value,
            }).then((res) => {
              if (res.status == 200) {
                ElMessage.success(res.data);
                onLoadDetail();
              }
            });
          });
        } else {
          duizhangdan_check(props.data?.id, {
            Type: type,
            Result: result,
          }).then((res) => {
            if (res.status == 200) {
              ElMessage.success(res.data);
              onLoadDetail();
            }
          });
        }
      });
    };

    const onPay = () => {
      ElMessageBox.confirm(`您确定对此对账单进行付款吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
        type: "warning",
      }).then(() => {
        duizhangdan_pay(props.data?.id).then((res) => {
          if (res.status == 200) {
            ElMessage.success(res.data);
            onLoadDetail();
          }
        });
      });
    };

    return {
      state,
      props,
      onLoadDetail,
      boxDom,
      tabsDom,
      http,
      onCheck,
      onPay,
    };
  },
};
</script>
<style lang="less">
.gysTab {
  .el-tabs__content {
    position: inherit;
  }
}
</style>
