<template>
  <div
    style="
      overflow: hidden;
      display: flex;
      flex-direction: column;
      height: 100%;
    "
  >
    <ul
      style="
        border-bottom: 1px solid #e2e2e2;
        padding: 15px;
        display: flex;
        align-items: center;
      "
    >
      <li style="flex: 1">权限配置</li>
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
    <ul style="font-size: 14px; padding-left: 20px; overflow-y: auto; flex: 1">
      <template v-for="item in state.data" :key="item">
        <div style="margin-bottom: 20px">
          <ul>
            <el-checkbox
              :checked="
                state.selectArr.includes(
                  'M_' + item.TableName + (item.Menu ? '_' + item.Menu : '')
                )
              "
              style="font-weight: 600"
              :label="
                'M_' + item.TableName + (item.Menu ? '_' + item.Menu : '')
              "
              @change="
                onCheckChange(
                  'M_' + item.TableName + (item.Menu ? '_' + item.Menu : ''),
                  $event
                )
              "
              :key="state.key"
              >{{ item.Title }}</el-checkbox
            >
          </ul>
          <ul
            style="padding-left: 10px"
            v-if="item.BtnArr?.length > 0"
            :key="state.key"
          >
            <el-checkbox
              v-for="btn in item.BtnArr"
              :key="btn"
              :checked="
                state.selectArr.includes('B_' + item.TableName + '_' + btn)
              "
              :label="'B_' + item.TableName + '_' + btn"
              @change="onCheckChange('B_' + item.TableName + '_' + btn, $event)"
              >{{ btn }}</el-checkbox
            >
          </ul>
          <ul
            style="padding-left: 10px"
            v-if="item.DataArr?.length > 0"
            :key="state.key"
          >
            <template v-for="btn in item.DataArr" :key="btn">
              <el-checkbox
                :checked="
                  state.selectArr.includes('D_' + item.TableName + '_' + btn)
                "
                :label="'D_' + item.TableName + '_' + btn"
                @change="onDataChange('D_' + item.TableName + '_', btn, $event)"
                >{{ btn }}
              </el-checkbox>
            </template>
          </ul>
        </div>
      </template>
    </ul>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { juese_getqx, juese_setqx } from "@/http/api";
import { ElMessage } from "element-plus";
export default {
  props: {
    id: Number,
  },
  setup(props, { emit }) {
    const state = reactive({
      selectArr: [],
      data: [
        {
          Title: "商品管理",
          TableName: "ShangPin_List",
          BtnArr: ["添加", "管理", "编辑", "删除"],
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "创建订单",
          TableName: "ShangPinDingDan_Add",
        },
        {
          Title: "商品订单",
          TableName: "ShangPinDingDan_List",
          BtnArr: ["管理"],
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "变更记录",
          TableName: "ShangPinDingDanBianGengJiLu_List",
          BtnArr: ["审核"],
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "旅游下单",
          TableName: "LvYouDingDan_Add",
        },
        {
          Title: "旅游订单",
          TableName: "LvYouDingDan_List",
          BtnArr: ["添加", "管理"],
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "个人客户",
          TableName: "KeHu_GeRen",
          BtnArr: ["添加", "管理", "编辑", "删除"],
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "单位客户",
          TableName: "KeHu_DanWei",
          BtnArr: ["添加", "管理", "编辑", "删除"],
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "同行客户",
          TableName: "KeHu_TongHang",
          BtnArr: ["添加", "管理", "编辑", "删除"],
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "旅客",
          TableName: "YouKe_List",
          BtnArr: ["添加", "编辑", "删除", "拉黑"],
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "黑名单管理",
          TableName: "YouKe_Hmd",
          BtnArr: ["编辑", "删除", "移除"],
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "到账认领",
          TableName: "DaoZhangGongShi_Rl",
          BtnArr: ["认领"],
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "供应商管理",
          TableName: "GongYingShang_List",
          BtnArr: ["添加", "管理", "编辑", "删除"],
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "商品对账",
          TableName: "ShangPinDingDan_Dz",
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "采购付款",
          TableName: "LvYouDingDan_Dz",
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        // {
        //   Title: "对账审核",
        //   TableName: "DuiZhangDan_ShenHe",
        //   DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        // },
        {
          Title: "导游档案",
          TableName: "DaoYou_List",
          BtnArr: ["添加", "编辑", "删除"],
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "导游补助",
          TableName: "DaoYou_BuZhu",
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "对账付款",
          TableName: "DuiZhangDan_Fk",
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "导游借款",
          TableName: "LvYouDingDan_Jhk",
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "订单退款审核",
          TableName: "LvYouDingDan_Tk",
        },
        {
          Title: "导补发放审核",
          TableName: "DaoYouBuZhu_Sh",
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "发团审核",
          TableName: "LvYouDingDanFaTuanJiLu_Sh",
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "结算审核",
          TableName: "LvYouDingDan_Js",
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "到账公示",
          TableName: "DaoZhangGongShi_List",
          BtnArr: ["添加", "撤回", "删除"],
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "旅游订单明细",
          TableName: "LvYouDingDan_Ck",
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "同业销售统计",
          TableName: "XiaoShouTongJi_List",
        },
        {
          Title: "通知公告",
          TableName: "GongGao_List",
          BtnArr: ["添加", "编辑", "删除"],
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "用户管理",
          TableName: "YongHu_List",
          BtnArr: ["添加", "编辑", "重置密码", "删除"],
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "部门管理",
          TableName: "BuMen_List",
          BtnArr: ["添加", "编辑", "删除"],
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "角色管理",
          TableName: "JueSe_List",
          BtnArr: ["添加", "授权", "绑定用户", "编辑", "删除"],
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "基础数据",
          TableName: "ZiDian_List",
          BtnArr: ["添加", "编辑", "删除"],
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "对公账户",
          TableName: "ZhangHu_List",
          BtnArr: ["添加", "编辑", "删除"],
          DataArr: ["全部", "部门", "部门及下级部门", "个人"],
        },
        {
          Title: "短息管理",
          TableName: "Sms_List",
          BtnArr: ["添加", "编辑", "删除"],
        },
      ],
    });
    onMounted(() => {
      juese_getqx(props.id).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          state.selectArr = res.data;
          state.key = Math.random();
        }
      });
    });

    const onCheckChange = (value, checked) => {
      console.log(value);
      if (checked) {
        state.selectArr.push(value);
      } else {
        const index = state.selectArr.findIndex((w) => w == value);
        if (index > -1) {
          state.selectArr.splice(index, 1);
        }
      }
      state.key = Math.random();
    };

    const onDataChange = (prefix, value, checked) => {
      if (checked) {
        const index = state.selectArr.findIndex((w) => w.includes(prefix));
        if (index > -1) {
          state.selectArr.splice(index, 1);
        }
        state.selectArr.push(prefix + value);
      } else {
        const index = state.selectArr.findIndex((w) => w == prefix + value);
        if (index > -1) {
          state.selectArr.splice(index, 1);
        }
      }
      state.key = Math.random();
    };

    const onSubmit = () => {
      console.log(state.selectArr);
      // let i = 1;
      // if (i == 1) return false;
      juese_setqx(props.id, state.selectArr).then((res) => {
        ElMessage({
          message: res.data,
          showClose: true,
          dangerouslyUseHTMLString: true,
          type: res.status ? "success" : "error",
        });
        if (res.status == 200) {
          setTimeout(() => {
            emit("close");
          }, 500);
        }
      });
    };

    return { state, onCheckChange, onDataChange, onSubmit };
  },
};
</script>
<style scoped></style>
