<template>
  <div class="listBox">
    <ul class="sBox">
      <li style="flex: 1; display: flex; align-items: center">
        <el-input
          v-model="state.params.Params.Key"
          style="width: 15%"
          placeholder="关键字"
          clearable
          @clear="
            delete state.params.Params.Key;
            onLoad();
          "
        ></el-input>
        <el-radio-group
          v-model="state.params.Params.Jkzt"
          style="margin-left: 10px"
          @change="onLoad()"
        >
          <el-radio-button>全部</el-radio-button>
          <el-radio-button :label="0">
            <text
              :style="{
                color: state.params.Params.Jkzt === 0 ? '#fff' : '#ffa500',
              }"
              >待借款</text
            >
          </el-radio-button>
          <el-radio-button :label="1"
            ><text
              :style="{
                color: state.params.Params.Jkzt === 1 ? '#fff' : '#f00',
              }"
              >待还款</text
            >
          </el-radio-button>
          <el-radio-button :label="2">
            <text
              :style="{
                color: state.params.Params.Jkzt === 2 ? '#fff' : '#080',
              }"
              >已还款
            </text>
          </el-radio-button>
          <!-- <el-radio-button
            :label="item.Key"
            v-for="item in state.ztData"
            :key="item"
          >
            <text
              :style="{
                color: state.params.Params.Zt == item.Key ? '' : item.Color,
              }"
            >
              {{ item.Text }}</text
            >
          </el-radio-button> -->
        </el-radio-group>
        <el-dropdown
          split-button
          @click="onLoad"
          type="primary"
          style="margin-left: 10px"
        >
          查询
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
                重置
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </li>
    </ul>
    <ul class="tBox">
      <el-table
        :data="state.tableData"
        v-loading="!Array.isArray(state.tableData)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
        :default-sort="{
          prop: state.params.Order,
          order: state.params.Asc == false ? 'descending' : 'ascending',
        }"
        @sort-change="onSortChange"
      >
        <el-table-column
          fixed
          label="#"
          width="50"
          type="index"
          :index="
            (index) =>
              (state.params.PageIndex - 1) * state.params.PageSize + index + 1
          "
          align="center"
        />
        <el-table-column
          sortable="JkztModel"
          label="状态"
          prop="JkztModel"
          :formatter="$tab_StatusFormat"
        />
        <el-table-column prop="Bh" sortable="Bh" label="订单号" />
        <el-table-column
          sortable="Zt"
          label="订单状态"
          prop="Zt"
          :formatter="$tab_StatusFormat"
        />
        <el-table-column
          prop="Ftsj"
          sortable="Ftsj"
          label="发团/回团"
          :formatter="
            (row) => $tab_GroupFormat(row.Ftsj || '-', row.Htsj || '')
          "
        />
        <el-table-column
          label="计调"
          :formatter="
            (row) => $tab_GroupFormat(row.JdyXm || '-', row.JdyBmMc || '')
          "
        />
        <el-table-column
          label="导游"
          prop="Dyxm"
          :formatter="$tab_TextFormat"
        />
        <el-table-column
          label="借款金额"
          prop="Jkje"
          sortable="Jkje"
          :formatter="$tab_MoneyFormat"
        />
        <el-table-column
          label="导游现收"
          prop="Dyxs"
          sortable="Dyxs"
          :formatter="$tab_MoneyFormat"
        />
        <el-table-column
          label="导游现付"
          prop="Dyxf"
          sortable="Dyxf"
          :formatter="$tab_MoneyFormat"
        />
        <el-table-column
          label="导补合计"
          prop="Dbhj"
          sortable="Dbhj"
          :formatter="$tab_MoneyFormat"
        />
        <el-table-column
          label="补助现发"
          prop="Bzxf"
          sortable="Bzxf"
          :formatter="$tab_MoneyFormat"
        />
        <el-table-column
          label="操作"
          width="180"
          align="center"
          fixed="right"
          className="tabBtn"
        >
          <template #default="{ row }">
            <el-button
              type="primary"
              :icon="Operation"
              link
              @click="
                state.selectId = row.Id;
                state.showDetail = true;
              "
              >查看
            </el-button>
            <el-button
              type="primary"
              :icon="Operation"
              link
              @click="
                state.selectId = row.Id;
                state.showJieKuan = true;
              "
              v-if="row.Jkzt == 0"
              >借款
            </el-button>
            <el-button
              type="warning"
              :icon="Operation"
              link
              @click="
                state.selectId = row.Id;
                state.showHuanKuan = true;
              "
              v-if="row.Jkzt == 1 && row.Zt.Key == 7"
              >还款
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </ul>
    <c_page
      :total="state.totalCount"
      :pageIndex="state.params.PageIndex"
      @onLoad="
        (pageIndex) => {
          state.params.PageIndex = pageIndex;
          onLoad();
        }
      "
    >
    </c_page>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showJieKuan"
        title="借款"
        @close="state.showJieKuan = false"
      >
        <c_LvYouDingDanJieKuanCompile
          @close="
            state.showJieKuan = false;
            onLoad();
          "
          :id="state.selectId"
        ></c_LvYouDingDanJieKuanCompile>
      </c_popup>
    </transition>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showHuanKuan"
        title="还款"
        @close="state.showHuanKuan = false"
      >
        <c_LvYouDingDanHuanKuanCompile
          @close="
            state.showHuanKuan = false;
            onLoad();
          "
          :id="state.selectId"
        ></c_LvYouDingDanHuanKuanCompile>
      </c_popup>
    </transition>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showDetail"
        title="借还款明细"
        @close="state.showDetail = false"
      >
        <c_LvYouDingDanJhkDetail
          @close="state.showDetail = false"
          :id="state.selectId"
        ></c_LvYouDingDanJhkDetail>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { lvyoudingdan_jhk, lvyoudingdan_getztselect } from "@/http/api";
import { useRouter } from "vue-router";

export default {
  components: {},
  setup() {
    const router = useRouter();
    const state = reactive({
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: {
          Zt: 0,
          TableName: "LvYouDingDan_Jhk",
        },
        Order: "Cjsj",
        Asc: false,
      },
    });
    onMounted(() => {
      lvyoudingdan_getztselect().then((res) => {
        if (res.status == 200) state.ztData = res.data;
      });

      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      lvyoudingdan_jhk(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //排序
    const onSortChange = (column) => {
      state.params.Order = column.prop;
      state.params.Asc = column.order === "ascending";
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      Object.assign(state, {
        params: {
          PageSize: 20,
          PageIndex: 1,
          Params: {},
          Order: "Cjsj",
          Asc: false,
        },
      });
      onLoad();
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
      router,
    };
  },
};
</script>
<style lang="less" scoped>
.drawerCss {
  width: 40vw !important;
}

.drawerCss > div {
  overflow: hidden;
  padding: 0 !important;
}
</style>
