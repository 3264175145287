<template>
  <div class="box" style="width: 100%; height: 100%; overflow: hidden">
    <div
      style="
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      "
    >
      <ul
        style="
          width: 100%;
          border: 1px solid #e2e2e2;
          border-bottom: 0;
          padding: 10px;
          display: flex;
          flex-wrap: wrap;
        "
      >
        <li style="flex: 1">
          <el-input
            v-model="state.params.Params.Key"
            style="width: 15%"
            placeholder="关键字"
            clearable
            @clear="
              delete state.params.Params.Key;
              onLoad();
            "
          ></el-input>
          <el-dropdown
            split-button
            @click="onLoad"
            type="primary"
            style="margin-left: 10px"
          >
            查询
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
                  重置
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </li>
        <li style="display: flex; align-items: center">
          <el-button type="success" icon="Select" @click="onSubmit">
            完成
          </el-button>
        </li>
      </ul>
      <ul
        style="
          overflow: hidden;
          display: flex;
          padding: 10px;
          padding-left: 0;
          border-top: 1px solid #e2e2e2;
        "
      >
        <li style="width: 250px; margin-right: 10px; overflow-y: auto">
          <el-tree
            :data="[
              {
                Mc: '肥西文旅集团旅游ERP综合管理平台',
                Xjs: state.treeData,
                Type: 0,
              },
            ]"
            :props="{ label: 'Mc', children: 'Xjs' }"
            accordion
            :highlight-current="true"
            :default-expand-all="true"
            node-key="Id"
            @node-click="onTreeClick"
            :current-node-key="state.params.Params.XjId"
          >
            <template #default="{ data }">
              <div
                style="display: flex; align-items: baseline"
                v-if="data.Type == 0"
              >
                {{ data.Mc }}
              </div>
              <div style="display: flex; align-items: baseline" v-else>
                <!-- <el-icon style="margin-right: 2px" size="12">
                  <Avatar />
                </el-icon> -->
                {{ data.Mc }}
              </div>
            </template>
          </el-tree>
        </li>
        <li
          style="
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: hidden;
          "
        >
          <el-table
            :data="state.tableData"
            v-loading="!Array.isArray(state.tableData)"
            element-loading-background="rgba(0, 0, 0, 0.2)"
            :default-sort="{
              prop: state.params.Order,
              order: state.params.Asc == false ? 'descending' : 'ascending',
            }"
            @sort-change="onSortChange"
            style="height: fit-content"
            highlight-current-row
            ref="tableDom"
            @row-click="onRowClick"
          >
            <el-table-column
              fixed
              label="#"
              width="50"
              type="index"
              :index="
                (index) =>
                  (state.params.PageIndex - 1) * state.params.PageSize +
                  index +
                  1
              "
              align="center"
            />
            <el-table-column
              prop="IsQy"
              sortable="IsQy"
              label="状态"
              align="center"
              width="80"
            >
              <template #default="scope">
                <text :style="{ color: scope.row.IsQy ? '#080' : '#f00' }">
                  {{ scope.row.IsQy ? "启用" : "停用" }}
                </text>
              </template>
            </el-table-column>
            <el-table-column
              prop="Xm"
              sortable="Xm"
              label="姓名"
              min-width="120"
              :formatter="
                (row, column, cellValue) =>
                  $tab_Highlinght(cellValue, state.params.Params.Key)
              "
            />
            <el-table-column
              prop="Xb"
              label="性别"
              :formatter="$tab_StatusFormat"
            />
            <el-table-column prop="BmMc" label="所属部门" min-width="200" />
            <el-table-column prop="JsMc" label="角色" min-width="120" />
            <el-table-column prop="Zw" label="职务" min-width="120" />
            <el-table-column prop="Sjhm" label="手机号码" min-width="140" />
          </el-table>

          <c_page
            :total="state.totalCount"
            :pageIndex="state.params.PageIndex"
            @onLoad="
              (pageIndex) => {
                state.params.PageIndex = pageIndex;
                onLoad();
              }
            "
          >
          </c_page>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { yonghu_list, bumen_tree } from "@/http/api";

export default {
  props: {
    id: Number,
  },
  setup(props, { emit }) {
    const tableDom = ref(null);
    const state = reactive({
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: {},
        Order: "Cjsj",
        Asc: false,
      },
    });
    onMounted(() => {
      onLoadTree();
      onLoad();
    });

    //加载组织机构树
    const onLoadTree = () => {
      bumen_tree(state.params.Params).then((res) => {
        if (res.status == 200) {
          state.treeData = res.data;
        }
      });
    };

    //树节点点击事件
    const onTreeClick = (data) => {
      state.params.Params.BmId = data.Id;
      onLoad();
    };

    const onLoad = () => {
      delete state.tableData;
      yonghu_list(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;

          tableDom.value.setCurrentRow(list.find((w) => w.Id == props.id));
        }
      });
    };

    //排序
    const onSortChange = (column) => {
      state.params.Order = column.prop;
      state.params.Asc = column.order === "ascending";
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      state.params.PageIndex = 1;
      state.params.PageSize = 20;
      state.params.Params = {};
      onLoad();
    };

    const onRowClick = (row) => {
      state.selectModel = row;
    };

    const onSubmit = () => {
      emit("close", state.selectModel);
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
      onSubmit,
      onTreeClick,
      onRowClick,
      tableDom,
      onLoadTree,
    };
  },
};
</script>
<style>
.el-table__body tr.current-row > td {
  background-color: #b7daf7 !important;
  color: #fff;
}
</style>
