<template>
  <div
    style="
      width: 100%;
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      padding: 10px;
      position: relative;
      background-color: #fff;
      z-index: 9999;
    "
    ref="boxDom"
  >
    <div style="display: flex; align-items: center">
      <text style="margin-right: 20px; font-size: 18px; font-weight: 600">{{
        state.model.Mc
      }}</text>
      <c_status :model="state.item.Zt" :size="16"></c_status>
      <li style="flex: 1"></li>
      <el-button icon="printer" @click="state.showXcd = true">
        打印行程单
      </el-button>
      <el-button
        icon="printer"
        v-if="[1, 2].includes(state.model.Zt)"
        @click="state.showFttzs = true"
      >
        打印发团通知书
      </el-button>
      <el-button
        icon="printer"
        v-if="[5, 7].includes(state.model.Zt)"
        @click="state.showJsd = true"
        >打印结算单</el-button
      >

      <!-- <el-button icon="printer" @click="state.showJsd = true"
        >打印结算单</el-button
      > -->
    </div>
    <div class="listBox" style="overflow-y: auto">
      <div class="infoBox">
        <div class="firstBox">
          <ul>
            <li>
              订单金额：<c_money
                :value="state.model.Zje"
                size="30"
                style="font-weight: 600"
              />
            </li>
            <li>
              已收金额：<c_money
                :value="state.model.Ysje"
                size="30"
                style="font-weight: 600; color: #080"
              />
            </li>
            <li>
              欠款金额：<c_money
                :value="state.model.Qkje"
                size="30"
                style="font-weight: 600; color: #ffa500"
              />
            </li>
            <li>
              采购成本：<c_money
                :value="state.model.Cgcb"
                size="30"
                style="font-weight: 600; color: #409eff"
              />
            </li>
          </ul>
          <ul>
            <el-button
              :type="primary"
              :icon="EditPen"
              link
              @click="state.showEdit = true"
              v-if="
                state.model.Zt != -1 &&
                state.model.Zt != 1 &&
                state.model.Zt != 5 &&
                state.model.Zt != 7
              "
            >
              修改订单信息
            </el-button>
            <el-button :icon="Lock" link @click="onSetMm">设置密码</el-button>
            <el-button
              v-if="state.model.Zt == 0"
              type="primary"
              @click="
                state.showFt = true;
                !state.ftModel.Lywz
                  ? (state.ftModel.Lywz =
                      '导游旗（ ）面  旗杆（ ）根  话筒（ ）个  质量反馈单（ ）份  发团通知书（ ）份  名单表（ ）份')
                  : '';
              "
              >计调发团</el-button
            >
            <el-button
              v-if="state.model.Zt == 2"
              type="warning"
              @click="onSubmitHt"
              >确认回团</el-button
            >
            <el-button
              v-if="state.model.Zt == 3"
              type="primary"
              @click="
                onLoadCgjl();
                state.bzModel.Dyxs = state.model.Dyxs;
                state.showBz = true;
              "
              >回团报账
            </el-button>
            <el-button
              v-if="state.model.Zt == 4"
              type="warning"
              @click="
                onLoadCgjl();
                state.bzModel.Dyxs = state.model.Dyxs;
                state.showBz = true;
              "
              >重新报账
            </el-button>
            <el-button
              v-if="state.model.Zt == 4"
              type="primary"
              @click="onSubmitJs()"
            >
              提交结算申请
            </el-button>
            <el-button
              v-if="state.model.Zt == 0"
              type="info"
              @click="onCancel()"
            >
              取消订单
            </el-button>
          </ul>
        </div>
      </div>
      <div
        class="infoBox"
        style="margin-top: 10px"
        v-if="state.model.Jkzt != null"
      >
        <div
          class="firstBox"
          style="
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 20px;
          "
        >
          <ul style="justify-content: flex-start">
            导游借款：<c_status :model="state.item.Jkzt"></c_status>
          </ul>
          <ul style="justify-content: flex-start; margin-top: 10px">
            借款金额：{{
              $priceFormat(state.model.Jkje)
            }}
            <text style="margin-left: 20px"
              >借款说明：{{ state.model.Jksm }}</text
            >
          </ul>
        </div>
      </div>
      <el-tabs
        v-model="state.tabsName"
        style="margin-top: 10px; position: inherit"
        class="comTab detailTab lydd-detail-tab"
        @tab-change="onTabsChange"
      >
        <el-tab-pane label="订单信息" name="mx">
          <el-descriptions :column="4" border>
            <el-descriptions-item
              label="订单编号/状态"
              label-align="right"
              class-name="lydd_model_item"
            >
              <li class="flex-row ai-center">
                <text>{{ state.model.Bh + "/" }}</text>
                <c_status :model="state.item.Zt"></c_status>
              </li>
            </el-descriptions-item>
            <el-descriptions-item
              label="订单类型"
              label-align="right"
              class-name="lydd_model_item"
            >
              {{ state.item.Lx?.Text }}
            </el-descriptions-item>
            <el-descriptions-item
              label="订单名称"
              label-align="right"
              class-name="lydd_model_item"
            >
              {{ state.model.Mc }}
            </el-descriptions-item>
            <el-descriptions-item
              label="发团日期"
              label-align="right"
              class-name="lydd_model_item"
            >
              {{ state.item.Ftsj || "-" }}
            </el-descriptions-item>
            <el-descriptions-item
              label="行程天数"
              label-align="right"
              class-name="lydd_model_item"
            >
              {{ state.model.Ts + "天" + state.model.Ys + "晚" }}
            </el-descriptions-item>
            <el-descriptions-item
              label="订单认数"
              label-align="right"
              class-name="lydd_model_item"
            >
              {{ state.model.Zrs }}
            </el-descriptions-item>
            <el-descriptions-item
              label="销售"
              label-align="right"
              class-name="lydd_model_item"
            >
              {{ state.model.XsyXm }}
              <text style="color: #a2a2a2">{{
                "[" + state.model.XsyBmMc + "]"
              }}</text>
            </el-descriptions-item>
            <el-descriptions-item
              label="计调"
              label-align="right"
              class-name="lydd_model_item"
            >
              {{ state.model.JdyXm }}
              <text style="color: #a2a2a2">{{
                "[" + state.model.JdyBmMc + "]"
              }}</text>
            </el-descriptions-item>
            <el-descriptions-item
              label="创建信息"
              label-align="right"
              class-name="lydd_model_item"
            >
              {{ state.model.CjrXm }}
              <text style="color: #a2a2a2" v-if="state.item.CjrBmMc">
                {{ "[" + state.item.CjrBmMc + "]" }}
              </text>
              <text style="color: #a2a2a2">
                {{ "[" + state.model.Cjsj + "]" }}
              </text>
            </el-descriptions-item>
            <el-descriptions-item
              label="客户信息"
              label-align="right"
              class-name="lydd_model_item"
            >
              {{ state.item.KhMc }}
              <text style="color: #a2a2a2">
                {{ "[" + state.item.KhBh + "]" }}
              </text>
            </el-descriptions-item>
            <el-descriptions-item
              label="游客联系人"
              label-align="right"
              class-name="lydd_model_item"
              :span="2"
            >
              {{ state.model.LxrXm }}
              <text style="color: #a2a2a2">
                {{ "[" + state.model.LxrDh + "]" }}
              </text>
            </el-descriptions-item>
            <el-descriptions-item
              label="订单备注"
              label-align="right"
              class-name="lydd_model_item"
            >
              {{ state.model.Bz || "-" }}
            </el-descriptions-item>
          </el-descriptions>
          <ul
            style="
              font-weight: 600;
              font-size: 14px;
              display: flex;
              align-items: flex-end;
              color: rgb(60, 60, 60);
              margin-top: 20px;
              margin-bottom: 10px;
            "
          >
            订单金额
          </ul>
          <el-table
            :data="state.mxs"
            :summary-method="onTableSum"
            show-summary
            style="border: 1px solid var(--el-border-color-lighter) !important"
            :header-cell-style="{ background: '#F5F7FA !important' }"
          >
            <el-table-column label="#" type="index" width="50" align="center" />
            <el-table-column
              prop="Lx"
              label="类型"
              :formatter="$tab_TextFormat"
              align="center"
            />
            <el-table-column
              prop="Mc"
              label="名称"
              :formatter="$tab_TextFormat"
              align="center"
            />
            <el-table-column
              prop="Dj"
              label="单价"
              :formatter="$tab_MoneyFormat"
              align="center"
            />
            <el-table-column
              prop="Sl1"
              label="数量"
              :formatter="$tab_TextFormat"
              align="center"
            >
              <template #default="{ row }">
                <text>{{ row.Sl1 }}</text>
                <text v-if="row.Dw1">{{ row.Dw1 }}</text>
                <text v-if="row.Sl2 > 1">{{ row.Sl2 }}</text>
                <text v-if="row.Dw2">{{ row.Dw2 }}</text>
              </template>
            </el-table-column>
            <el-table-column
              prop="Zj"
              label="金额"
              :formatter="$tab_MoneyFormat"
              align="center"
            />
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="行程信息" name="xcxx">
          <c_LvYouDingDanXcxx
            :id="props.data?.id"
            :search="state.model.Zt == -1"
            :key="state.key"
          ></c_LvYouDingDanXcxx>
        </el-tab-pane>
        <el-tab-pane label="预定信息" name="ydxx">
          <c_LvYouDingDanYdxx
            :id="props.data?.id"
            :search="state.model.Zt == -1"
            :key="state.key"
          ></c_LvYouDingDanYdxx>
        </el-tab-pane>
        <el-tab-pane label="附件信息" name="fj">
          <c_LvYouDingDanFuJian
            :id="props.data?.id"
            :search="state.model.Zt == -1"
            :key="state.key"
          ></c_LvYouDingDanFuJian>
        </el-tab-pane>
        <el-tab-pane label="名单管理" name="yk">
          <c_LvYouDingDanYouKe
            :id="props.data?.id"
            :mc="state.model?.Mc"
            @onLoad="onLoad()"
            @onShowEdit="state.showEdit = true"
            :search="state.model.Zt == -1"
            :key="state.key"
          ></c_LvYouDingDanYouKe>
        </el-tab-pane>
        <el-tab-pane label="电子合同" name="ht">
          <c_LvYouDingDanHeTong
            :id="props.data?.id"
            :model="state.model"
            :search="state.model.Zt == -1"
            :key="state.key"
          ></c_LvYouDingDanHeTong>
        </el-tab-pane>
        <el-tab-pane label="订单收退款" name="sk">
          <c_LvYouDingDanShouKuan
            :id="props.data?.id"
            :model="state.model"
            @onLoad="onLoad()"
            :search="state.model.Zt == -1"
            :key="state.key"
          ></c_LvYouDingDanShouKuan>
        </el-tab-pane>
        <el-tab-pane label="采购管理" name="cg">
          <c_LvYouDingDanCaiGou
            :id="props.data?.id"
            :model="state.model"
            @onLoad="onLoad()"
            :search="state.model.Zt == -1"
            :key="state.key"
          ></c_LvYouDingDanCaiGou>
        </el-tab-pane>
        <el-tab-pane label="导游派遣" name="dy">
          <c_LvYouDingDanDaoYou
            :id="props.data?.id"
            :dates="[state.model.Ftsj, state.model.Htsj]"
            :search="state.model.Zt == -1"
            :key="state.key"
          ></c_LvYouDingDanDaoYou>
        </el-tab-pane>
        <el-tab-pane label="操作日志" name="rz">
          <c_LvYouDingDanRiZhi
            :id="props.data?.id"
            :key="state.key"
          ></c_LvYouDingDanRiZhi>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog v-model="state.showCancelDialog" title="取消订单">
      <el-input
        type="textarea"
        v-model="state.qxBz"
        :autosize="{ minRows: 5 }"
        placeholder="请输入取消原因"
      ></el-input>
      <template #footer>
        <el-button type="primary" @click="onCancel()">确认取消</el-button>
      </template>
    </el-dialog>
    <el-dialog v-model="state.showFt" title="计调发团" width="50%">
      <el-form :model="state.ftModel" class="numberForm" label-width="100px">
        <el-form-item label="预计现付" :label-width="formLabelWidth">
          {{ $priceFormat(state.model.Cgyjxf) }} 元
        </el-form-item>
        <el-form-item label="借款金额" :label-width="formLabelWidth">
          <el-input-number
            v-model="state.ftModel.Jkje"
            :min="0"
            :precision="2"
            :controls="false"
            style="margin-right: 5px"
          />元
        </el-form-item>
        <el-form-item label="借款说明">
          <el-input
            v-model="state.ftModel.Jksm"
            type="textarea"
            :autosize="{ minRows: 5 }"
          />
        </el-form-item>
        <el-form-item label="领用物资">
          <el-input
            v-model="state.ftModel.Lywz"
            type="textarea"
            :autosize="{ minRows: 5 }"
          />
        </el-form-item>
        <el-form-item label="其他注意事项">
          <el-input
            v-model="state.ftModel.Zysx"
            type="textarea"
            :autosize="{ minRows: 5 }"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="onSubmitFt(1)">无需审核直接发团</el-button>
          <el-button type="primary" @click="onSubmitFt(2)">
            提交发团审核
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog
      v-model="state.showBz"
      title="导游报账"
      width="80%"
      :append-to-body="true"
      custom-class="lydd_bzBox"
    >
      <el-form :model="state.bzModel" class="numberForm" label-width="120px">
        <el-form-item label="线路/团队名称" :label-width="formLabelWidth">
          {{ state.model.Mc }}
        </el-form-item>
        <el-form-item label="订单编号">
          {{ state.model.Bh }}
        </el-form-item>
        <el-form-item label="发团日期">
          {{ state.model.Ftsj }}
        </el-form-item>
        <el-form-item label="导游借款">
          <c_money :value="state.model.Jkje"></c_money>
        </el-form-item>
        <el-form-item label="导游现收">
          <el-input-number
            v-model="state.bzModel.Dyxs"
            :min="0"
            :precision="2"
            :controls="false"
            style="margin-right: 5px"
          />元
        </el-form-item>
        <el-form-item label="报账说明">
          <el-input
            v-model="state.bzModel.Bzsm"
            type="textarea"
            :autosize="{ minRows: 5 }"
            style="margin-right: 20px"
          />
        </el-form-item>
      </el-form>
      <ul
        style="
          font-size: 16px;
          margin-bottom: 20px;
          padding-left: 10px;
          color: #000;
        "
      >
        采购信息
      </ul>
      <div
        style="
          background: #f6f6f6;
          padding: 10px;
          margin: 10px;
          border-radius: 8px;
        "
      >
        <ul v-for="item in state.cgjlData" :key="item">
          <div style="background: #fff; padding: 10px 0">
            <ul
              style="
                display: flex;
                align-items: center;
                margin-bottom: 1px solid #e2e2e2;
                padding: 0 10px;
              "
            >
              <li style="display: flex; align-items: center">
                {{ item.Bh
                }}<text style="color: #a2a2a2"
                  >({{ item.Lb1 + "-" + item.Lb2 }})</text
                >
              </li>
              <li
                style="
                  flex: 1;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #000;
                "
              >
                <text style="color: #a2a2a2">已付/未付：</text
                >{{ $priceFormat(item.Yf) + "/" + $priceFormat(item.Qk) }}
                <text style="color: #a2a2a2; margin-left: 20px">合计：</text>
                <c_money :value="item.Zje" />
              </li>
              <li style="display: flex; align-items: center">
                {{ item.GysMc }}
              </li>
            </ul>
            <ul
              style="
                display: flex;
                align-items: center;
                margin: 10px;
                background: #f6f6f6;
                border-radius: 10px;
                overflow: hidden;
              "
              v-for="mx in item.Mxs"
              :key="mx"
            >
              <li
                style="
                  padding: 20px 30px;
                  background: #02a7f0;
                  color: #fff;
                  margin-right: 20px;
                "
              >
                {{ mx.Lx }}
              </li>
              <li style="flex: 1">{{ mx.Mc }}</li>
              <li style="flex: 1; display: flex; align-items: center">
                <text style="color: #a2a2a2">单价：</text>
                <c_money :value="mx.Dj"></c_money>
              </li>
              <li style="flex: 1; display: flex; align-items: center">
                <text style="color: #a2a2a2">数量：</text>{{ mx.Sl1 }}
                <text v-if="mx.Dw1">{{ mx.Dw1 }}</text>
                <text v-if="mx.Sl2">{{ mx.Sl2 }}</text>
                <text v-if="mx.Dw2">{{ mx.Dw2 }}</text>
              </li>
              <li style="flex: 1; display: flex; align-items: center">
                <text style="color: #a2a2a2">合计金额：</text>
                <c_money :value="mx.Zj"></c_money>
              </li>
              <li style="flex: 1; display: flex; align-items: center">
                <text style="color: #a2a2a2">预计现付：</text>
                <c_money :value="mx.Yjxf"></c_money>
              </li>
              <li style="flex: 1; padding: 10px">
                <el-input
                  type="number"
                  v-model.number="mx.Sjxf"
                  placeholder="--实际现付--"
                >
                  <template #append>元</template>
                </el-input>
              </li>
            </ul>
          </div>
        </ul>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="onSubmitBz"> 提交 </el-button>
        </div>
      </template>
    </el-dialog>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showEdit"
        title="修改旅游订单"
        @close="state.showEdit = false"
      >
        <c_LvYouDingDanEdit
          @close="
            state.showEdit = false;
            onLoad();
            state.key = Math.random();
          "
          :id="props.data?.id"
        ></c_LvYouDingDanEdit>
      </c_popup>
    </transition>
    <div class="lyddDetail_fttzs_shade" v-if="state.showFttzs == '2323'">
      <div class="lyddDetail_fttzs_box">
        <ul
          style="
            position: relative;
            text-align: center;
            font-weight: 600;
            padding: 10px 0;
            border-bottom: 1px solid rgba(100, 100, 100, 0.1);
          "
        >
          肥西文旅集团发团通知书
          <text
            style="position: absolute; right: 20px; top: 10px"
            class="lyddDetail_fttzs_close"
            @click="state.showFttzs = false"
            >X
          </text>
        </ul>
        <ul style="display: flex; align-items: center; padding: 10px">
          <li style="font-weight: 600; flex: 1">团队信息</li>
          <el-button
            type="primary"
            icon="Download"
            size="small"
            @click="onExportFttzsPdf"
          >
            导出PDF
          </el-button>
          <el-button
            type="primary"
            icon="Printer"
            plain
            size="small"
            @click="onPrintFttzs"
          >
            打印
          </el-button>
        </ul>
        <div
          style="padding: 0 10px; overflow: hidden; color: rgba(0, 0, 0, 0.8)"
        >
          <div style="border: 1px solid #e2e2e2; border-radius: 10px">
            <ul
              style="
                border-bottom: 1px solid #e2e2e2;
                display: flex;
                align-items: center;
                padding: 10px;
              "
            >
              <text style="font-weight: 600; font-size: 15px">{{
                state.model.Mc
              }}</text>
              <text
                style="
                  font-size: 11px;
                  margin-left: 10px;
                  color: rgba(0, 0, 0, 0.8);
                "
                >{{ state.model.Bh }}</text
              >
              <c_status :model="state.model.Zt"></c_status>
            </ul>
            <ul style="display: flex; align-items: center; padding: 10px">
              <li style="display: flex; align-items: center">
                <text style="color: #a2a2a2">计调:</text>
                <text>
                  {{
                    state.item.JdyXm +
                    (state.model.JdyBmMc ? " - " + state.model.JdyBmMc : "")
                  }}
                </text>
              </li>
              <li style="display: flex; align-items: center; margin: 0 20px">
                <text style="color: #a2a2a2">日期:</text>
                <text>
                  {{ state.item.Ftsj + "至" + state.item.Htsj }}
                </text>
              </li>
              <li style="display: flex; align-items: center">
                <text style="color: #a2a2a2">天数:</text>
                <text>
                  {{ state.model.Ts + "天" + state.model.Ys + "晚" }}
                </text>
              </li>
              <li style="display: flex; align-items: center">
                <text style="color: #a2a2a2">人数:</text>
                <text>
                  {{ state.model.Zrs }}
                </text>
              </li>
            </ul>
          </div>
          <div class="jsdetail_title">费用明细</div>
          <table style="text-align: right">
            <tr>
              <th>销售金额</th>
              <th>已收</th>
              <th>未收</th>
              <th>采购成本</th>
              <th>导游补助</th>
              <th>成本合计</th>
              <th>利润</th>
            </tr>
            <tr>
              <td>{{ $priceFormat(state.model.Zje || 0) }}</td>
              <td>{{ $priceFormat(state.model.Ysje || 0) }}</td>
              <td>{{ $priceFormat(state.model.Qkje || 0) }}</td>
              <td>{{ $priceFormat(state.model.Cgcb || 0) }}</td>
              <td>{{ $priceFormat(state.model.Dbhj || 0) }}</td>
              <td>
                {{ $priceFormat(state.model.Cgcb + state.model.Dbhj || 0) }}
              </td>
              <td>
                {{
                  $priceFormat(
                    state.model.Zje - state.model.Cgcb - state.model.Dbhj || 0
                  )
                }}
              </td>
            </tr>
          </table>
          <div class="jsdetail_title">采购信息</div>
          <table style="text-align: left">
            <tr>
              <th>类别</th>
              <th>采购项</th>
              <th>供应商</th>
              <th>合计</th>
              <th>预计现付</th>
              <th>实际现付</th>
              <th>已付</th>
              <th>未付</th>
            </tr>
            <tr v-for="item in state.cgs" :key="item">
              <td>{{ item.LbMc }}</td>
              <td style="flex: 2">
                <ul v-for="mx in item.Mxs" :key="mx">
                  {{
                    mx.Lx +
                    " - " +
                    mx.Mc +
                    " " +
                    $priceFormat(mx.Dj || 0) +
                    " * " +
                    mx.Sl1 +
                    (mx.Sl2 > 0 ? " * " + mx.Sl2 : "") +
                    " = " +
                    $priceFormat(mx.Zj || 0) +
                    " 元"
                  }}
                </ul>
              </td>
              <td>{{ item.GysMc }}</td>
              <td>{{ $priceFormat(item.Zje || 0) }}</td>
              <td>{{ $priceFormat(item.Yjxf || 0) }}</td>
              <td>{{ $priceFormat(item.Sjxf || 0) }}</td>
              <td>{{ $priceFormat(item.Yf || 0) }}</td>
              <td>{{ $priceFormat(item.Qk || 0) }}</td>
            </tr>
            <!-- <tr>
              <td colspan="3" style="text-align: right">合计：</td>
              <td>{{ $priceFormat(state.model.Cgcb) }}</td>
              <td>{{ $priceFormat(state.model.Cgyjxf) }}</td>
              <td>{{ $priceFormat(state.model.Cgsjxf) }}</td>
              <td>{{ $priceFormat(state.model.Cgyf) }}</td>
              <td>{{ $priceFormat(state.model.Cgqk) }}</td>
            </tr> -->
          </table>
          <div class="jsdetail_title">导游信息</div>
          <table style="text-align: left">
            <tr>
              <th style="widows: 40%">带团日期</th>
              <th>导游姓名</th>
              <th>带团说明</th>
              <th style="text-align: center">补助是否现发</th>
              <th style="text-align: right">补助金额</th>
            </tr>
            <tr v-for="item in state.dys" :key="item">
              <td>{{ item.Dtksrq + " ~ " + item.Dtjsrq }}</td>
              <td>
                {{ item.Xm }}
              </td>
              <td>{{ item.Sm || "-" }}</td>
              <td style="text-align: center">{{ item.IsXf ? "是" : "否" }}</td>
              <td style="text-align: right">{{ $priceFormat(item.Bzje) }}</td>
            </tr>
            <tr>
              <td colspan="4" style="text-align: right; font-weight: 600">
                合计：
              </td>
              <td style="text-align: right">
                {{ $priceFormat(state.model.Dbhj) }}
              </td>
            </tr>
          </table>
          <div class="jsdetail_title">借还款信息</div>
          <div style="border: 1px solid #e2e2e2; margin-bottom: 20px">
            <ul style="display: flex; overflow: hidden">
              <li
                style="
                  background-color: #f5f7fa;
                  font-weight: 600;
                  text-align: right;
                  width: 120px;
                  padding: 10px;
                  border-right: 1px solid #e2e2e2;
                "
              >
                借款金额：
              </li>
              <li style="flex: 1.5; padding: 10px">
                {{ $priceFormat(state.model.Jkje) }}
              </li>
              <li
                style="
                  background-color: #f5f7fa;
                  font-weight: 600;
                  text-align: right;
                  width: 120px;
                  padding: 10px;
                  border-right: 1px solid #e2e2e2;
                  border-left: 1px solid #e2e2e2;
                "
              >
                导游现收：
              </li>
              <li style="flex: 1.5; padding: 10px">
                {{ $priceFormat(state.model.Dyxs) }}
              </li>
              <li
                style="
                  background-color: #f5f7fa;
                  font-weight: 600;
                  text-align: right;
                  width: 120px;
                  padding: 10px;
                  border-right: 1px solid #e2e2e2;
                  border-left: 1px solid #e2e2e2;
                "
              >
                导游现付：
              </li>
              <li style="flex: 1.5; padding: 10px">
                {{ $priceFormat(state.model.Dyxf) }}
              </li>
              <li
                style="
                  background-color: #f5f7fa;
                  font-weight: 600;
                  text-align: right;
                  width: 120px;
                  padding: 10px;
                  border-right: 1px solid #e2e2e2;
                  border-left: 1px solid #e2e2e2;
                "
              >
                导补现发：
              </li>
              <li style="flex: 1.5; padding: 10px">
                {{ $priceFormat(state.model.Bzxf) }}
              </li>
              <li
                style="
                  background-color: #f5f7fa;
                  font-weight: 600;
                  text-align: right;
                  padding: 10px;
                  border-right: 1px solid #e2e2e2;
                  border-left: 1px solid #e2e2e2;
                "
              >
                导游应还：
              </li>
              <li style="flex: 1.5; padding: 10px">
                {{
                  $priceFormat(
                    state.model.Jkje +
                      state.model.Dyxs -
                      state.model.Dyxf -
                      state.model.Bzxf
                  )
                }}
              </li>
            </ul>
            <ul
              style="
                display: flex;
                align-items: center;
                overflow: hidden;
                border-top: 1px solid #e2e2e2;
              "
            >
              <li
                style="
                  background-color: #f5f7fa;
                  font-weight: 600;
                  text-align: right;
                  width: 120px;
                  padding: 10px;
                  border-right: 1px solid #e2e2e2;
                "
              >
                借款说明：
              </li>
              <li style="flex: 1; padding: 10px">
                {{ state.model.Jksm || "-" }}
              </li>
            </ul>
            <ul
              style="
                display: flex;
                align-items: center;
                overflow: hidden;
                border-top: 1px solid #e2e2e2;
              "
            >
              <li
                style="
                  background-color: #f5f7fa;
                  font-weight: 600;
                  text-align: right;
                  width: 120px;
                  padding: 10px;
                  border-right: 1px solid #e2e2e2;
                "
              >
                领用物资：
              </li>
              <li style="flex: 1; padding: 10px">
                {{ state.model.FtLywz || "-" }}
              </li>
            </ul>
            <ul
              style="
                display: flex;
                align-items: center;
                overflow: hidden;
                border-top: 1px solid #e2e2e2;
              "
            >
              <li
                style="
                  background-color: #f5f7fa;
                  font-weight: 600;
                  text-align: right;
                  width: 120px;
                  padding: 10px;
                  border-right: 1px solid #e2e2e2;
                "
              >
                其他注意事项：
              </li>
              <li style="flex: 1; padding: 10px">
                {{ state.model.FtZysx || "-" }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <transition name="el-zoom-in-center">
    <c_popup
      v-if="state.showFttzs"
      title="发团通知书"
      @close="state.showFttzs = false"
    >
      <c_LvYouDingDanFttzs
        v-if="state.showFttzs"
        :id="props.data?.id"
      ></c_LvYouDingDanFttzs>
    </c_popup>
  </transition>
  <transition name="el-zoom-in-center">
    <c_popup v-if="state.showXcd" title="行程单" @close="state.showXcd = false">
      <c_LvYouDingDanXcd
        v-if="state.showXcd"
        :id="props.data?.id"
      ></c_LvYouDingDanXcd>
    </c_popup>
  </transition>
  <transition name="el-zoom-in-center">
    <c_popup v-if="state.showJsd" title="结算单" @close="state.showJsd = false">
      <c_LvYouDingDanJsd
        v-if="state.showJsd"
        :id="props.data?.id"
      ></c_LvYouDingDanJsd>
    </c_popup>
  </transition>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import {
  lvyoudingdan_detail,
  lvyoudingdan_addftjl,
  lvyoudingdan_cancel,
  lvyoudingdan_qrht,
  lvyoudingdan_getcg,
  lvyoudingdan_qrbz,
  lvyoudingdan_tjjs,
  lvyoudingdan_szmm,
} from "@/http/api";
import { ElMessage, ElLoading, ElMessageBox } from "element-plus";
import { tab_MoneyFormat } from "@/util/tFormat";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import printJS from "print-js";
export default {
  props: {
    data: Object,
  },
  setup(props) {
    const divDom = ref(null);
    const state = reactive({
      model: {},
      item: {},
      tabsName: "mx",
      ftModel: { Jkje: 0 }, //发团操作对象
      bzModel: { Dyxs: 0 }, //报账操作对象
    });
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      lvyoudingdan_detail(props.data?.id).then((res) => {
        if (res.status == 200) {
          const { model, item, mxs, dys, cgs } = res.data;
          state.model = model;
          state.item = item;
          state.mxs = mxs;
          state.dys = dys;
          state.cgs = cgs;
        }
      });
    };

    const onCancel = () => {
      ElMessageBox.confirm("您确定要取消该订单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
        type: "warning",
      }).then(() => {
        lvyoudingdan_cancel(props.data?.id).then((res) => {
          ElMessage({
            message: res.data,
            showClose: true,
            dangerouslyUseHTMLString: true,
            type: res.status ? "success" : "error",
          });

          if (res.status == 200) {
            onLoad();
            state.key = Math.random();
          }
        });
      });
    };

    //选项卡切换事件
    const onTabsChange = (value) => {
      if (value == "mx") {
        state.mxKey = Math.random();
      } else if (value == "yk") {
        state.ykKey = Math.random();
      } else if (value == "bgjl") {
        state.bgjlKey = Math.random();
      } else if (value == "rz") {
        state.rzKey = Math.random();
      }
    };

    const onTableSum = () => {
      let res = [];
      res[4] = "合计";

      res[5] = tab_MoneyFormat(null, null, state.model.Zje);
      //   res[5] = (state.model.Mxs || [])
      //     .map((m) => {
      //       return m.Zj;
      //     })
      //     .reduce((x, y) => {
      //       return x + y;
      //     });

      return res;
    };

    const onSubmitFt = (type) => {
      state.ftModel.Lx = type;
      lvyoudingdan_addftjl(props.data?.id, state.ftModel).then((res) => {
        if (res.status == 200) {
          ElMessage.success(res.data);
          onLoad();
          state.showFt = false;
          state.key = Math.random();
        }
      });
    };

    const onSubmitHt = () => {
      lvyoudingdan_qrht(props.data?.id).then((res) => {
        if (res.status == 200) {
          ElMessage.success(res.data);
          onLoad();
          state.showBz = false;
          state.key = Math.random();
        }
      });
    };

    const onLoadCgjl = () => {
      lvyoudingdan_getcg(props.data?.id).then((res) => {
        if (res.status == 200) {
          state.cgjlData = res.data;
        }
      });
    };

    const onSubmitBz = () => {
      state.bzModel.Mxs = [];
      state.cgjlData.forEach((e) => {
        e.Mxs.forEach((e) => {
          state.bzModel.Mxs.push({ Id: e.Id, Sjxf: e.Sjxf });
        });
      });

      lvyoudingdan_qrbz(props.data?.id, state.bzModel).then((res) => {
        if (res.status == 200) {
          ElMessage.success(res.data);
          onLoad();
          state.showBz = false;
          state.key = Math.random();
        }
      });
    };

    const onSubmitJs = () => {
      lvyoudingdan_tjjs(props.data?.id).then((res) => {
        if (res.status == 200) {
          ElMessage.success(res.data);
          onLoad();
          state.key = Math.random();
        }
      });
    };

    //导出发团通知书
    const onExportFttzsPdf = async () => {
      const loading = ElLoading.service({
        lock: true,
        text: "数据加载中，请稍后……",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const element = document.getElementById("divDom");
      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "a4",
      });

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(state.model.Mc + "-发团通知书.pdf");
      loading.close();
    };

    //打印发团通知书
    const onPrintFttzs = async () => {
      const loading = ElLoading.service({
        lock: true,
        text: "数据加载中，请稍后……",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const element = document.getElementById("divDom");
      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL("image/png");
      console.log(imgData);

      printJS({
        printable: imgData,
        type: "image",
        style: `@media print { @page {size: auto; margin: 0; } body{margin:10px}}`,
        documentTitle: "",
      });
      loading.close();
    };

    const onSetMm = () => {
      ElMessageBox.prompt("请输入密码", "设置密码", {
        confirmButtonText: "保存",
        cancelButtonText: "取消",
        inputPattern: /\S+/,
        inputValue: state.model?.Mm,
        inputErrorMessage: "密码不能为空",
      })
        .then(({ value }) => {
          lvyoudingdan_szmm(props.data?.id, value).then((res) => {
            if (res.status == 200) {
              ElMessage.success(res.data);
              onLoad();
            }
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Input canceled",
          });
        });
    };

    return {
      state,
      onLoad,
      onCancel,
      onTabsChange,
      props,
      onTableSum,
      onSubmitFt,
      onSubmitHt,
      onLoadCgjl,
      onSubmitBz,
      onSubmitJs,
      onExportFttzsPdf,
      divDom,
      onPrintFttzs,
      onSetMm,
    };
  },
};
</script>
<style lang="less" scoped>
.infoBox {
  .title {
    width: 80px;
  }
}

.firstBox {
  display: flex;
  align-items: center;
  padding: 20px;

  & > ul:first-child {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    font-size: 16px;
    color: #a2a2a2;

    & > li {
      display: flex;
      align-items: center;
      margin-left: 20px;
    }
  }
}

.modelBox {
  & > ul {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 20px;

    & > li {
      display: flex;
      align-items: center;
      flex: 1;
      & > text:first-child {
        color: #a2a2a2;
      }
    }
  }
}

.lyddDetail_fttzs_shade {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  overflow-y: auto;
  animation: fadeIn 0.8s 0.02s ease backwards;

  .lyddDetail_fttzs_box {
    width: 70%;
    margin: 10vh 15%;
    min-height: 80%;
    background-color: #fff;
    border-radius: 8px;

    .lyddDetail_fttzs_close {
      color: #a2a2a2;
      cursor: pointer;
      font-weight: 500;

      &:hover {
        transform: scale(1.2);
      }
    }

    .modelBox {
      & > ul {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 20px;

        & > li {
          display: flex;
          align-items: center;
          flex: 1;
          & > text:first-child {
            color: #a2a2a2;
          }
        }
      }
    }

    table {
      border-collapse: collapse;
      width: 100%;
    }

    th {
      border-top-left-radius: 10px;
      background-color: #f5f7fa;
    }

    td,
    th {
      border: 1px solid #e2e2e2;
      padding: 10px;
    }

    .jsdetail_title {
      font-weight: 600;
      margin: 10px 0;
    }

    .jsdetail_tabBox {
      border: 1px solid #e2e2e2;
      border-radius: 10px;
      width: 100%;
      overflow: hidden;

      & > ul {
        display: flex;
        align-items: center;
        & > li {
          flex: 1;
          padding: 10px;
          overflow: hidden;
        }

        & > li:not(:last-child) {
          border-right: 1px solid #e2e2e2;
        }
      }

      & > ul:first-child {
        font-weight: 600;
        background-color: #f7f7f7;
      }

      & > ul:not(:last-child) {
        border-bottom: 1px solid #e2e2e2;
      }
    }
  }
}
</style>
<style lang="less">
.lydd_bzBox {
  .el-dialog__body {
    padding: 0 !important;
  }

  .el-form-item {
    margin-bottom: 10px !important;
  }
}

.lydd-detail-tab {
  .el-descriptions__label {
    white-space: nowrap;
  }
}
</style>
