<template>
  <div class="detailBox">
    <ul class="detailContent" style="overflow-y: hidden">
      <div style="display: flex; height: 100%; overflow: hidden">
        <div style="flex: 1">
          <el-form
            :model="state.model"
            label-width="100px"
            :scroll-to-error="true"
            ref="formDom"
          >
            <el-form-item label="订单名称">
              {{ props.model.Mc }}
            </el-form-item>
            <el-form-item label="订单编号">
              {{ props.model.Bh }}
            </el-form-item>
            <el-form-item
              label="采购对象"
              prop="Lx"
              :rules="[
                { required: true, message: '请选择采购对象', trigger: 'blur' },
              ]"
            >
              <el-radio-group v-model="state.model.Lx" class="ml-4">
                <el-radio :label="1">供应商采购</el-radio>
                <!-- <el-radio :label="2">其他采购</el-radio> -->
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="供应商"
              :rules="[
                {
                  required: state.model.Lx == 1,
                  message: '请选择供应商',
                  trigger: 'blur',
                },
              ]"
              prop="GysId"
            >
              <el-select
                v-model="state.model.GysId"
                filterable
                allow-create
                default-first-option
                :reserve-keyword="false"
              >
                <el-option
                  v-for="item in state.gysData"
                  :key="item"
                  :label="item.Mc"
                  :value="item.Id"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label="采购类别"
              prop="Lb1Id"
              style="display: flex; align-items: center"
              :rules="[
                {
                  required: true,
                  validator: onLbValidator,
                  trigger: 'blur',
                },
              ]"
            >
              <el-select
                v-model="state.model.Lb1Id"
                filterable
                allow-create
                default-first-option
                :reserve-keyword="false"
                style="flex: 1"
              >
                <el-option
                  v-for="item in state.lb1Data"
                  :key="item"
                  :label="item.Mc"
                  :value="item.Id"
                />
              </el-select>
              <el-select
                v-model="state.model.Lb2Id"
                filterable
                allow-create
                default-first-option
                :reserve-keyword="false"
                style="flex: 1; margin-left: 20px"
              >
                <el-option
                  v-for="item in state.lb2Data"
                  :key="item"
                  :label="item.Mc"
                  :value="item.Id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="采购说明" prop="Bz">
              <el-input
                v-model="state.model.Sm"
                type="textarea"
                :autosize="{ minRows: 10 }"
              />
            </el-form-item>
          </el-form>
        </div>
        <div
          style="
            flex: 2;
            margin-left: 50px;
            overflow-y: hidden;
            display: flex;
            flex-direction: column;
          "
        >
          <div
            style="
              font-weight: 600;
              font-size: 14px;
              padding-left: 10px;
              display: flex;
              align-items: flex-end;
              color: rgb(60, 60, 60);
            "
          >
            采购明细
          </div>
          <ul
            style="
              width: 100%;
              display: flex;
              overflow-y: hidden;
              margin-top: 10px;
              flex-direction: column;
              flex: 1;
            "
          >
            <el-table
              :data="state.bodies"
              style="
                width: 100%;
                border: 0;
                border-right: 0 !important;
                border-bottom: 0;
              "
              class="lycgcompile_tab"
              :border="false"
              :summary-method="onBuildTotal"
              show-summary
            >
              <el-table-column label="采购类型" width="140" align="center">
                <template #default="{ row }">
                  <el-select
                    v-model="row.Lx"
                    value-key="Mc"
                    :clearable="false"
                    @change="onLxChange($event, row)"
                  >
                    <el-option
                      v-for="item in lyddCgxData"
                      :key="item"
                      :label="item.Lx"
                      :value="item"
                    />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="采购名称" width="140" align="center">
                <template #default="{ row }">
                  <el-input v-model="row.Mc" :placeholder="row.Mmc"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="单价" width="160" align="center">
                <template #default="{ row }">
                  <el-input-number
                    v-model="row.Dj"
                    :precision="2"
                    :controls="false"
                    class="number-input-suffix"
                    :suffix="
                      '元' +
                      (row.Mc ? '/' : '') +
                      (row.Dw1 || '') +
                      (row.Dw2 || '')
                    "
                    @change="onMxChange"
                    style="width: 100%"
                  />
                </template>
              </el-table-column>
              <el-table-column label="数量" width="160" align="center">
                <template #default="{ row }">
                  <div style="white-space: nowrap; display: flex">
                    <el-input
                      v-model.number="row.Sl1"
                      :placeholder="row.Dw1"
                      type="number"
                      input-style="text-align:right"
                      @change="onMxChange"
                      style="flex: 1"
                    >
                      <template #append>{{ row.Dw1 }}</template></el-input
                    >
                    <el-input
                      v-if="row.Dw2"
                      v-model.number="row.Sl2"
                      :placeholder="row.Dw2"
                      type="number"
                      input-style="text-align:right"
                      @change="onMxChange"
                      style="flex: 1"
                    >
                      <template #append>{{ row.Dw2 }}</template></el-input
                    >
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="预计现付" width="160" align="center">
                <template #default="{ row }">
                  <el-input-number
                    v-model="row.Yjxf"
                    :precision="2"
                    :controls="false"
                    class="number-input-suffix"
                    suffix="元"
                    style="width: 100%"
                  />
                  <!-- <c_inputnumber
                    :value="row.Yjxf"
                    suffix="元"
                    @Value-Change="
                      (val) => {
                        row.Yjxf = val;
                      }
                    "
                    style="width: 120px"
                    :min="0"
                    :key="state.key"
                  /> -->
                </template>
              </el-table-column>
              <el-table-column
                prop="Sjxf"
                label="实际现付"
                width="100"
                align="center"
                :formatter="$tab_MoneyFormat"
              />
              <el-table-column
                prop="Zj"
                label="订单金额"
                width="140"
                align="center"
                fixed="right"
                :formatter="$tab_MoneyFormat"
              />
              <el-table-column
                label="操作"
                fixed="right"
                v-if="!props.search"
                width="60"
                align="center"
              >
                <template #default="{ $index }">
                  <el-button
                    :icon="Plus"
                    link
                    v-if="$index == 0"
                    @click="
                      state.bodies.push({
                        Lx: '线路',
                        Mc: '成人价',
                        Dw1: '人',
                        Yjxf: 0,
                      })
                    "
                    style="font-size: 20px"
                  >
                  </el-button>
                  <el-button
                    type="danger"
                    :icon="Delete"
                    link
                    v-else
                    style="font-size: 20px"
                    @click="
                      state.bodies.splice(index, 1);
                      onMxChange();
                    "
                  >
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </ul>
          <!-- 
          <ul
            style="
              margin-top: 10px;
              border-top: 1px solid #e2e2e2;
              display: flex;
              align-items: center;
              padding-top: 10px;
            "
          >
            <li style="flex: 1; text-align: center; font-weight: 600">合计</li>
            <li style="flex: 5.5; margin-right: 40px"></li>
            <li style="flex: 0.5; text-align: center">
              <c_money
                :value="state.model.Zje"
                style="text-align: center; margin: 0 auto; font-weight: 600"
              ></c_money>
            </li>
            <li style="width: 80px"></li>
          </ul> -->
        </div>
      </div>
    </ul>
    <ul class="detailBtn">
      <li
        style="
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          color: #f00;
        "
      >
        合计：<c_money
          :value="state.model.Zje"
          :size="20"
          style="font-weight: 600"
        ></c_money>
      </li>

      <el-button type="primary" @click="onSubmit()" style="margin-right: 20px"
        >提交</el-button
      >
    </ul>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, watch } from "vue";
import {
  lvyoudingdan_getcgdetail,
  lvyoudingdan_addcg,
  lvyoudingdan_editcg,
  zidian_select,
  gongyingshang_select,
} from "@/http/api";
import { lyddCgxData } from "@/util/enum";
import { ElMessage } from "element-plus";
import { numberIsNull2, priceFormat } from "@/util/tFormat";

export default {
  props: {
    id: Number,
    ddId: Number,
    model: Object,
  },
  setup(props, { emit }) {
    const formDom = ref(null);
    const { proxy } = getCurrentInstance();
    const state = reactive({
      model: {
        Lx: 1,
      },
      bodies: [{ Lx: "线路", Mc: "成人价", Dw1: "人", Yjxf: 0 }],
      jsData: [],
    });
    onMounted(() => {
      zidian_select("采购类别").then((res) => {
        if (res.status == 200) {
          state.lb1Data = res.data;
        }
      });

      gongyingshang_select().then((res) => {
        if (res.status == 200) state.gysData = res.data;
      });

      if (props.id) {
        lvyoudingdan_getcgdetail(props.id).then((res) => {
          if (res.status == 200) {
            state.model = res.data;
            state.bodies = res.data.Mxs;
            state.key = Math.random();
            zidian_select(
              state.lb1Data.find((w) => w.Id == state.model.Lb1Id)?.Mc
            ).then((res) => {
              if (res.status == 200) {
                state.lb2Data = res.data;
                if (res.data.length > 0) state.model.Lb2Id = res.data[0].Id;
              }
            });
          }
        });
      }
    });

    const onMxChange = () => {
      state.model.Zje = 0;
      for (let i = 0; i < state.bodies.length; i++) {
        if (
          !numberIsNull2(state.bodies[i].Dj) &&
          !numberIsNull2(state.bodies[i].Sl1)
        )
          state.bodies[i].Zj =
            state.bodies[i].Dj *
            state.bodies[i].Sl1 *
            (state.bodies[i].Sl2 || 1);
        state.model.Zje += state.bodies[i].Zj || 0;
      }
    };

    const onSubmit = () => {
      formDom.value.validate((valid) => {
        if (valid) {
          for (let i = 0; i < state.bodies.length; i++) {
            if (!state.bodies[i].Mc) {
              ElMessage.warning(`第${i + 1}项价格项名称不能为空！`);
              return false;
            }
            if (!state.bodies[i].Dj) {
              ElMessage.warning(`第${i + 1}项价格项单价不能为空！`);
              return false;
            }
            if (!state.bodies[i].Sl1) {
              ElMessage.warning(`第${i + 1}项价格项数量不能为空！`);
              return false;
            }
            if (state.bodies[i].Dw2) {
              if (!state.bodies[i].Sl2) {
                ElMessage.warning(`第${i + 1}项价格项数量不能为空！`);
                return false;
              }
            }
            state.bodies[i].Zj =
              state.bodies[i].Dj *
              state.bodies[i].Sl1 *
              (state.bodies[i].Sl2 || 1);
          }

          state.model.Mxs = state.bodies;

          if (props.id) {
            lvyoudingdan_editcg(props.id, state.model).then((res) => {
              proxy.$post_response(res, emit);
            });
          } else {
            lvyoudingdan_addcg(props.ddId, state.model).then((res) => {
              proxy.$post_response(res, emit);
            });
          }
        }
      });
    };

    watch(
      () => state.model.Lb1Id,
      (newVal) => {
        console.log(state.lb1Data.find((w) => w.Id == newVal));
        zidian_select(state.lb1Data.find((w) => w.Id == newVal)?.Mc).then(
          (res) => {
            if (res.status == 200) {
              state.lb2Data = res.data;
              if (res.data.length > 0) state.model.Lb2Id = res.data[0].Id;
            }
          }
        );
      }
    );

    const onLbValidator = (rule, value, callback) => {
      if (!state.model.Lb1Id || !state.model.Lb2Id)
        callback(new Error("请选择采购类别"));
      else callback();
    };

    const onLxChange = (item, row) => {
      row.Lx = item.Lx;
      row.Mc = item.Mc;
      row.Dw1 = item.Dw1;
      row.Dw2 = item.Dw2 || "";
    };

    const onBuildTotal = () => {
      let sum = [];
      sum[0] = "合计:";
      sum[6] = priceFormat(state.model.Zje);
      return sum;
    };

    return {
      state,
      formDom,
      onSubmit,
      props,
      lyddCgxData,
      onLxChange,
      onMxChange,
      onBuildTotal,
      onLbValidator,
    };
  },
};
</script>
<style lang="less">
.numberInput {
  & .el-input-number,
  & .el-input__inner {
    text-align: left !important;
  }

  & .el-input__wrapper {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.lycgcompile_tab {
  .el-input-group__append,
  .el-input-group__prepend {
    padding: 0 10px !important;
  }
}
</style>
