<template>
  <div class="listBox" style="background-color: #f6f6f6; padding: 0">
    <ul style="background-color: #fff; padding: 10px; border-radius: 5px">
      <el-input
        v-model="state.params.Params.Key"
        style="width: 15%"
        placeholder="关键字"
        clearable
        @clear="
          delete state.params.Params.Key;
          onLoad();
        "
      ></el-input>
      <el-input
        v-model="state.params.Params.KhMc"
        placeholder="请选择客户"
        readonly
        style="width: 300px; margin-left: 10px"
      >
        <template #append>
          <el-button type="primary" @click="state.showKh = true"
            >选择</el-button
          >
        </template>
      </el-input>
      <el-date-picker
        v-model="state.params.Params.Date"
        type="date"
        placeholder="使用日期"
        :size="size"
        style="margin-left: 10px"
        format="YYYY-MM-DD"
        value-format="YYYY-MM-DD"
        :disabled-date="onJudgeDate"
        :clearable="false"
      />
      <el-dropdown
        split-button
        @click="onLoad"
        type="primary"
        style="margin-left: 10px"
      >
        查询
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
              重置
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </ul>
    <ul
      style="
        background-color: #fff;
        padding: 10px;
        border-radius: 5px;
        margin-top: 10px;
        display: flex;
      "
    >
      <div style="padding: 5px 10px; width: 80px; text-align: right">
        品类：
      </div>
      <div style="flex: 1; display: flex; flex-wrap: wrap">
        <div
          style="
            padding: 5px 10px;
            margin-right: 10px;
            border-radius: 3px;
            cursor: pointer;
          "
          :class="
            state.params.Params.Pls.includes(item.Id) ? 'cTagSelect' : 'cTag'
          "
          v-for="item in state.plData"
          :key="item"
          @click="onPlClick(item.Id)"
        >
          {{ item.Mc }}
        </div>
      </div>
    </ul>
    <ul
      style="
        background-color: #fff;
        padding: 10px;
        border-radius: 5px;
        margin-top: 10px;
        display: flex;
      "
    >
      <div style="padding: 5px 10px; width: 80px; text-align: right">
        目的地：
      </div>
      <div style="flex: 1; display: flex; flex-wrap: wrap">
        <div
          style="
            padding: 5px 10px;
            margin-right: 10px;
            border-radius: 3px;
            cursor: pointer;
          "
          :class="state.params.Params.Mdd == item.Mc ? 'cTagSelect' : 'cTag'"
          v-for="item in state.mddData"
          :key="item"
          @click="onMddClick(item.Mc)"
        >
          {{ item.Mc }}
        </div>
      </div>
    </ul>
    <ul
      style="
        background-color: #fff;
        padding: 20px;
        border-radius: 5px;
        margin-top: 10px;
      "
    >
      <el-button
        :type="state.params.Order == 'Scs' ? 'primary' : 'info'"
        link
        @click="
          state.params.Order = 'Scs';
          onLoad();
        "
      >
        推荐排序
      </el-button>
      <el-button
        :type="state.params.Order == 'Xss' ? 'primary' : 'info'"
        link
        @click="
          state.params.Order = 'Xss';
          onLoad();
        "
      >
        销量优先
      </el-button>
    </ul>
    <ul
      style="
        padding-top: 10px;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
      "
    >
      <div
        class="product"
        v-for="item in state.tableData"
        :key="item"
        @click="
          $store.commit('openMenu', {
            path: 'ShangPinDingDanXdDetail',
            title: '商品详情',
            data: {
              id: item.Id,
              khId: state.params.Params.KhId,
              date: state.params.Params.Date,
              isXy: state.params.Params.IsXy,
            },
            refresh: true,
          })
        "
      >
        <li style="width: 100px; height: 100px; overflow: hidden">
          <el-image
            :src="$http.defaults.baseURL + item.Fm"
            style="width: 100px; min-height: 100px"
          >
            <template #error>
              <div
                style="
                  width: 100%;
                  height: 100px;
                  background-color: #f6f6f6;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #a2a2a2;
                "
              >
                暂无图片
              </div>
            </template>
          </el-image>
        </li>
        <div style="flex: 1; margin-left: 10px">
          <ul style="display: flex; align-items: center">
            <li style="flex: 1">{{ item.Mc }}</li>
          </ul>
          <ul>
            <li
              style="
                color: #fff;
                padding: 2px 5px;
                background-color: #7ab1e7;
                width: fit-content;
                font-size: 10px;
              "
            >
              {{ item.PlMc }}
            </li>
          </ul>
          <ul style="font-size: 12px; margin-top: 5px">
            <span style="color: #a2a2a2; font-size: 10px">联系电话:</span>
            {{
              item.Dh
            }}
          </ul>
          <ul style="display: flex; font-size: 10px; margin-top: 5px">
            <li style="flex: 1; height: 30px; overflow: hidden; color: #a2a2a2">
              {{ item.Jj }}
            </li>
            <li
              style="display: flex; align-items: end; margin-left: 5px"
              class="oldPrice"
            >
              <span style="font-size: 8px">￥</span>{{ $priceFormat(item.Scj) }}
            </li>
          </ul>
          <ul
            style="
              text-align: right;
              margin-top: 5px;
              color: #f00;
              font-weight: 600;
            "
          >
            <span style="font-size: 8px">￥</span>
            {{
              $priceFormat(item.Xsj)
            }}
          </ul>
        </div>
      </div>
    </ul>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showKh"
        title="选择同行客户"
        @close="state.showKh = false"
      >
        <c_ShangPinDingDanXdXzKh
          :data="state.params.Params"
          @close="onXzKhClose"
        ></c_ShangPinDingDanXdXzKh>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { zidian_select, shangpin_xdget } from "@/http/api";
import { ElMessage, dayjs } from "element-plus";
import http from "@/http";

export default {
  components: {},
  setup() {
    const state = reactive({
      params: {
        Params: {
          Pls: [],
          Mdds: [],
        },
        Order: "Cjsj",
        Asc: false,
      },
    });
    onMounted(() => {
      state.params.Params.Date = dayjs().format("YYYY-MM-DD");

      loadPl();
      loadMdd();
    });

    //禁用非有效期内的日期
    const onJudgeDate = (value) => {
      if (dayjs(value) < dayjs(dayjs().format("YYYY-MM-DD 00:00:00"))) {
        return true;
      } else return false;
    };

    //加载品类下拉数据
    const loadPl = () => {
      zidian_select("商品品类").then((res) => {
        if (res.status == 200) {
          state.plData = res.data;
        }
      });
    };

    //加载目的地下拉框
    const loadMdd = () => {
      zidian_select("目的地").then((res) => {
        if (res.status == 200) {
          state.mddData = res.data;
        }
      });
    };

    const onLoad = () => {
      if (!state.params.Params.KhId) {
        ElMessage.warning("请先选择客户！");
        return false;
      }
      if (!state.params.Params.Date) {
        ElMessage.warning("请选择日期！");
        return false;
      }

      delete state.tableData;
      shangpin_xdget(state.params).then((res) => {
        if (res.status == 200) {
          state.tableData = res.data;
        }
      });
    };

    //打开内容页
    const onOpenCompile = (id) => {
      state.compileTitle = id ? "修改" : "添加";
      state.selectId = id;
      state.showCompile = true;
    };

    //关闭内容页
    const onCloseCompile = (refresh) => {
      state.showCompile = false;
      if (refresh) {
        onLoad();
      }
    };

    //排序
    const onSortChange = (column) => {
      state.params.Order = column.prop;
      state.params.Asc = column.order === "ascending";
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      delete state.params.Params.Key;
      state.params.Params.Pls = [];
      delete state.params.Params.Mdd;

      state.params.Order = "Xss";
      state.params.Asc = false;
      onLoad();
    };

    const onPlClick = (id) => {
      const index = state.params.Params.Pls.indexOf(id);

      if (index > -1) {
        state.params.Params.Pls.splice(index, 1);
      } else {
        state.params.Params.Pls.push(id);
      }
    };

    const onMddClick = (mc) => {
      if (state.params.Params.Mdd == mc) delete state.params.Params.Mdd;
      else state.params.Params.Mdd = mc;
    };

    //同行客户选择界面关闭事件
    const onXzKhClose = (item) => {
      state.params.Params.KhId = item.Id;
      state.params.Params.KhMc = item.Mc;
      state.params.Params.IsXy = item.IsXy;
      state.showKh = false;
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
      onCloseCompile,
      onOpenCompile,
      onPlClick,
      onMddClick,
      http,
      onJudgeDate,
      onXzKhClose,
    };
  },
};
</script>
<style lang="less" scoped>
.drawerCss {
  width: 40vw !important;
}

.drawerCss > div {
  overflow: hidden;
  padding: 0 !important;
}

.product {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  width: calc(25% - 10px);
  overflow: hidden;
  margin-bottom: 10px;
  display: flex;
  margin-right: 10px;
}

.product:hover {
  box-shadow: 0 0 10px 1px #e2e2e2;
}

.cTag:hover {
  background: #409eff;
  color: #fff;
}

.cTagSelect {
  animation: bg-color 1s ease-in-out;
  color: #fff;
  background-color: #68b3ff;
}

@keyframes bg-color {
  from {
    color: #409eff;
    background-color: transparent;
  } /*从透明色开始*/
  to {
    background-color: #68b3ff;
  } /*变成目标颜色*/
}

.oldPrice {
  color: #a2a2a2;
  position: relative;
}

.oldPrice::after {
  content: "";
  border-bottom: 1px solid #a2a2a2;
  bottom: 5px;
  width: 100%;
  position: absolute;
}

.cIco {
  cursor: pointer;
}

.cIco:hover {
  color: #ffa500;
}
</style>
