<template>
  <router-view />
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  font-size: 13px;
}

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

/*分页控件样式 */
.pageControl {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.pageControl > .el-pagination {
  margin: 0 auto;
  text-align: center;
}

/*滚动条样式 */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #61b0f5;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: transparent;
}

.detailBox {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.detailContent {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  overflow-x: hidden;
}

.detailBtn {
  height: 50px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  border-top: 1px solid #e2e2e2;
  box-shadow: 2px -2px 5px #e2e2e2;
}

/*el-select样式*/
.el-select {
  width: 100%;
}

/*数字文本框取消上下箭头 */
input[type="number"] {
  -moz-appearance: textfield;
}

/*带树组件的列表样式 */
.treeListBox {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  position: relative;
  padding: 10px 10px 10px 0;
  .lBox {
    margin-right: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .rBox {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .sBox {
      width: 100%;
      border: 1px solid #e2e2e2;
      border-bottom: 0;
      padding: 10px;
      display: flex;
      flex-wrap: wrap;
    }

    .tBox {
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
  }
}

/*通用列表样式 */
.listBox {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 10px;
  /*position: relative;*/

  .sBox {
    width: 100%;
    border: 1px solid var(--el-border-color-lighter);
    border-bottom: 0;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
  }

  .tBox {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}

/*表格样式 */
.tabCss {
  border-top: 1px solid var(--el-border-color-lighter);
  border-left: 1px solid var(--el-border-color-lighter);
  border-right: 1px solid var(--el-border-color-lighter) !important;
}

.tabHeader {
  background: var(--el-fill-color-light) !important;
  color: var(--el-table-header-text-color);
  font-family: "微软雅黑";
  font-size: 12px;
}

.tabHeader .sort-caret.descending {
  border-top-color: #a2a2a2 !important;
}

.descending .sort-caret.descending {
  border-top-color: #f00 !important;
}

.tabHeader .sort-caret.ascending {
  border-bottom-color: #a2a2a2 !important;
}

.ascending .sort-caret.ascending {
  border-bottom-color: #396aff !important;
}

/**编号刷新按钮样式 */

.bhRefresh {
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.detailTab {
  .el-tabs__content {
    position: inherit;
  }
}

/* 详情页数据基础信息框 */
.infoBox {
  box-shadow: 0 0 10px 0 #e2e2e2;
  padding: 10px;
  border-radius: 7px;
  line-height: 18px;

  & > ul {
    display: flex;
    align-items: flex-start;

    & > li {
      display: flex;
      align-items: flex-start;
      flex: 1;
    }
  }

  & > ul:not(:last-child) {
    margin-bottom: 10px;
  }

  .title {
    color: #a2a2a2;
    width: 70px;
    display: flex;

    & > li {
      flex: 1;
      text-align: justify-all;
      word-break: break-all;
      text-align-last: justify;
    }
  }

  .content {
    flex: 1;
  }
}

.tab_doubleRow {
  white-space: nowrap;

  & > text:first-child {
    font-size: 14px;
  }

  & > text:last-child {
    font-size: 12px;
    color: #a2a2a2;
  }
}

.numberForm {
  & .el-input-number {
    & .el-input__inner {
      text-align: left !important;
    }
  }
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.ai-center {
  align-items: center;
}

.jc-center {
  justify-content: center;
}

.descriptions-item-default {
  white-space: nowrap !important;
}

.number-input-suffix {
  position: relative;
  width: 100%;
  &::after {
    content: attr(suffix);
    border-left: var(--el-border);
    display: flex;
    align-items: center;
    font-size: 12px;
    background-color: #f5f7fa;
    padding: 0 10px;
    position: absolute;
    right: 1px;
    top: 1px;
    height: calc(100% - 2px);
    color: var(--el-color-info);
    border-top-right-radius: var(--el-border-radius-base);
    border-bottom-right-radius: var(--el-border-radius-base);
  }

  .el-input__wrapper {
    padding-right: 44px !important;
    padding-left: 10px !important;
  }
}

.number-input-left {
  .el-input__inner {
    text-align: left !important;
  }
}
</style>
