<template>
  <div
    style="
      position: relative;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #e2e2e2;
    "
  >
    <!-- <div
      style="
        text-align: center;
        border-bottom: 1px solid #e2e2e2;
        height: 70px;
        border-right: 1px solid #e2e2e2;
        display: flex;
        align-items: center;
        padding: 0 10px;
      "
    >
      <el-image
        :src="require('@/assets/images/logo.png')"
        style="height: 40px"
        fit="contain"
      ></el-image>
    </div> -->
    <div style="flex: 1; overflow-y: auto" class="menu">
      <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        :collapse="!state.showMenu"
        style="overflow: hidden; border-right: 0"
      >
        <el-image
          v-if="state.showMenu"
          :src="require('@/assets/images/logo.png')"
          style="width: 120px; margin: 10px"
          fit="contain"
        ></el-image>
        <el-image
          v-else
          :src="require('@/assets/images/logo1.png')"
          style="width: 40px; margin: 10px"
          fit="contain"
        ></el-image>
        <el-menu-item index="/home" @click="onMenuClick('HomeMain', '首页')">
          <el-icon :size="state.menuIconSize"><HomeFilled /></el-icon>
          <template #title>
            <span class="firstMenu">首页</span>
          </template>
        </el-menu-item>
        <el-menu-item
          index="/sp"
          @click="onMenuClick('ShangPinList', '商品管理')"
          v-if="
            $store.state.loginUser?.IsAdmin ||
            $store.state.loginUser?.Qxs?.includes('M_ShangPin_List')
          "
        >
          <el-icon :size="state.menuIconSize"><GoodsFilled /></el-icon>
          <template #title>
            <span class="firstMenu">商品管理</span>
          </template>
        </el-menu-item>
        <el-sub-menu
          index="2"
          v-if="
            $store.state.loginUser?.IsAdmin ||
            $store.state.loginUser?.Qxs?.some(
              (w) =>
                w == 'M_ShangPinDingDan_Add' ||
                w == 'M_ShangPinDingDan_List' ||
                w == 'M_ShangPinDingDanBianGengJiLu_List' ||
                w == 'M_LvYouDingDan_List'
            )
          "
        >
          <template #title>
            <el-icon :size="state.menuIconSize"><List /></el-icon>
            <span class="firstMenu">订单管理</span>
          </template>
          <el-menu-item
            index="2-1"
            class="secondMenu"
            @click="onMenuClick('ShangPinDingDanXdList', '创建订单')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_ShangPinDingDan_Add')
            "
            >创建订单</el-menu-item
          >
          <el-menu-item
            index="/spdd"
            class="secondMenu"
            @click="onMenuClick('ShangPinDingDanList', '商品订单')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes(
                'M_ShangPinDingDanBianGengJiLu_List'
              )
            "
          >
            商品订单
          </el-menu-item>
          <el-menu-item
            index="/bgjl"
            class="secondMenu"
            @click="onMenuClick('ShangPinDingDanBianGengJiLuList', '变更记录')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes(
                'M_ShangPinDingDanBianGengJiLu_List'
              )
            "
          >
            变更记录
          </el-menu-item>
          <el-menu-item
            index="2-3"
            class="secondMenu"
            @click="onMenuClick('LvYouDingDanAdd', '旅游下单')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_LvYouDingDan_Add')
            "
          >
            旅游下单
          </el-menu-item>
          <el-menu-item
            index="2-4"
            class="secondMenu"
            @click="onMenuClick('LvYouDingDanList', '旅游订单')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_LvYouDingDan_List')
            "
          >
            旅游订单
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu
          index="3"
          v-if="
            $store.state.loginUser?.IsAdmin ||
            $store.state.loginUser?.Qxs?.some(
              (w) =>
                w == 'M_KeHu_GeRen' ||
                w == 'M_KeHu_DanWei' ||
                w == 'M_KeHu_TongHang' ||
                w == 'M_YouKe_List' ||
                w == 'M_YouKe_Hmd' ||
                w == 'M_DaoZhangGongShi_RenLing'
            )
          "
        >
          <template #title>
            <el-icon :size="state.menuIconSize"><PhoneFilled /></el-icon>
            <span class="firstMenu">客户管理</span>
          </template>
          <el-menu-item
            index="3-1"
            class="secondMenu"
            @click="onMenuClick('KeHuGeRenList', '个人客户')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_KeHu_GeRen')
            "
          >
            个人客户
          </el-menu-item>
          <el-menu-item
            index="3-2"
            class="secondMenu"
            @click="onMenuClick('KeHuDanWeiList', '单位客户')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_KeHu_DanWei')
            "
          >
            单位客户
          </el-menu-item>
          <el-menu-item
            index="3-3"
            class="secondMenu"
            @click="onMenuClick('KeHuTongHangList', '同行客户')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_KeHu_TongHang')
            "
          >
            同行客户
          </el-menu-item>
          <!-- <el-menu-item
            index="/thkh"
            class="secondMenu"
            @click="onMenuClick('TongHangKeHuList', '同行客户')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_KeHuTongHang_List')
            "
          >
            同行客户
          </el-menu-item> -->
          <el-menu-item
            index="3-4"
            class="secondMenu"
            @click="onMenuClick('YouKeList', '旅客')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_YouKe_List')
            "
          >
            旅客
          </el-menu-item>
          <el-menu-item
            index="3-5"
            class="secondMenu"
            @click="onMenuClick('YouKeHmdList', '黑名单管理')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_YouKe_Hmd')
            "
          >
            黑名单管理
          </el-menu-item>
          <el-menu-item
            index="/dzrl"
            class="secondMenu"
            @click="onMenuClick('DaoZhangGongShiRlList', '到账认领')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_DaoZhangGongShi_RenLing')
            "
          >
            到账认领
          </el-menu-item>
        </el-sub-menu>
        <!-- <el-sub-menu index="4">
          <template #title>
            <el-icon :size="state.menuIconSize"><Grid /></el-icon>
            <span class="firstMenu">计调操作</span>
          </template>
          <el-menu-item index="4-1" class="secondMenu">订单管理</el-menu-item>
          <el-menu-item index="4-2" class="secondMenu">协同计调</el-menu-item>
          <el-menu-item index="4-3" class="secondMenu">操作审核</el-menu-item>
        </el-sub-menu> -->
        <el-sub-menu
          index="5"
          v-if="
            $store.state.loginUser?.IsAdmin ||
            $store.state.loginUser?.Qxs?.some(
              (w) =>
                w == 'M_GongYingShang_List' ||
                w == 'M_ShangPinDingDan_Dz' ||
                w == 'M_LvYouDingDan_Dz'
            )
          "
        >
          <template #title>
            <el-icon :size="state.menuIconSize"><Stamp /></el-icon>
            <span class="firstMenu">供应链</span>
          </template>
          <el-menu-item
            index="/gys"
            class="secondMenu"
            @click="onMenuClick('GongYingShangList', '供应商管理')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_GongYingShang_List')
            "
          >
            供应商管理
          </el-menu-item>
          <el-menu-item
            index="/dz"
            class="secondMenu"
            @click="onMenuClick('DuiZhangDanSpddList', '商品对账')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_ShangPinDingDan_Dz')
            "
            >商品对账
          </el-menu-item>
          <!-- <el-menu-item
            index="/dzls"
            class="secondMenu"
            @click="onMenuClick('DuiZhangDanList', '对账审核')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_DuiZhangDan_ShenHe')
            "
          >
            对账审核
          </el-menu-item> -->
          <el-menu-item
            index="/cgdz"
            class="secondMenu"
            @click="onMenuClick('DuiZhangDanLycgList', '采购付款')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_LvYouDingDan_Dz')
            "
          >
            采购付款
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu
          index="6"
          v-if="
            $store.state.loginUser?.IsAdmin ||
            $store.state.loginUser?.Qxs?.some(
              (w) => w == 'M_DaoYou_List' || w == 'M_DaoYou_BuZhu'
            )
          "
        >
          <template #title>
            <el-icon :size="state.menuIconSize"><Share /></el-icon>
            <span class="firstMenu">导游管理</span>
          </template>
          <el-menu-item
            index="6-1"
            class="secondMenu"
            @click="onMenuClick('DaoYouList', '导游档案')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_DaoYou_List')
            "
            >导游档案</el-menu-item
          >
          <!-- <el-menu-item index="6-2" class="secondMenu">导游动态</el-menu-item>
          <el-menu-item index="6-3" class="secondMenu">导游派遣</el-menu-item> -->
          <el-menu-item
            index="6-4"
            class="secondMenu"
            @click="onMenuClick('DaoYouBuZhuDypq', '导游补助')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_DaoYou_BuZhu')
            "
            >导游补助</el-menu-item
          >
        </el-sub-menu>
        <el-sub-menu
          index="7"
          v-if="
            $store.state.loginUser?.IsAdmin ||
            $store.state.loginUser?.Qxs?.some(
              (w) =>
                w == 'DuiZhangDan_Fk' ||
                w == 'M_LvYouDingDan_Jhk' ||
                w == 'M_DaoYouBuZhu_Sh' ||
                w == 'M_LvYouDingDan_Ft' ||
                w == 'M_LvYouDingDan_Js' ||
                w == 'M_DaoZhangGongShi_List'
            )
          "
        >
          <template #title>
            <el-icon :size="state.menuIconSize"><Histogram /></el-icon>
            <span class="firstMenu">财务操作</span>
          </template>
          <el-menu-item
            index="7-1"
            class="secondMenu"
            @click="onMenuClick('DuiZhangDanFkList', '付款管理')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_DuiZhangDan_Fk')
            "
          >
            付款管理
          </el-menu-item>
          <el-menu-item
            index="7-2"
            class="secondMenu"
            @click="onMenuClick('LvYouDingDanJhk', '导游借还款')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_LvYouDingDan_Jhk')
            "
            >导游借还款</el-menu-item
          >
          <el-menu-item
            index="7-3"
            class="secondMenu"
            @click="onMenuClick('LvYouDingDanTuiKuanList', '订单退款')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_LvYouDingDan_Tk')
            "
            >订单退款</el-menu-item
          >
          <el-menu-item
            index="7-4"
            class="secondMenu"
            @click="onMenuClick('DaoYouBuZhuList', '导补发放管理')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_DaoYouBuZhu_Sh')
            "
          >
            导补发放管理
          </el-menu-item>
          <!-- <el-menu-item
            index="7-5"
            class="secondMenu"
            @click="onMenuClick('LvYouDingDanJieSuanList', '商品订单结算')"
          >
            商品订单结算
          </el-menu-item> -->
          <el-menu-item
            index="7-6"
            class="secondMenu"
            @click="onMenuClick('LvYouDingDanFaTuanJiLuList', '发团审核')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes(
                'M_LvYouDingDanFaTuanJiLu_Sh'
              )
            "
            >发团审核</el-menu-item
          >
          <el-menu-item
            index="7-7"
            class="secondMenu"
            @click="onMenuClick('LvYouDingDanJieSuanList', '结算审核')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_LvYouDingDan_Js')
            "
          >
            结算审核
          </el-menu-item>
          <!-- <el-menu-item index="7-8" class="secondMenu">发票管理</el-menu-item> -->
          <el-menu-item
            index="/dzgs"
            class="secondMenu"
            @click="onMenuClick('DaoZhangGongShiList', '到账公示')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_DaoZhangGongShi_List')
            "
            >到账公示</el-menu-item
          >
          <el-menu-item
            index="7-9"
            class="secondMenu"
            @click="onMenuClick('LvYouDingDanSearchList', '旅游订单明细')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_LvYouDingDan_Ck')
            "
            >旅游订单明细</el-menu-item
          >
          <!-- <el-menu-item index="7-10" class="secondMenu">
            全渠道资金管理
          </el-menu-item> -->
        </el-sub-menu>
        <el-sub-menu
          index="8"
          v-if="
            $store.state.loginUser?.IsAdmin ||
            $store.state.loginUser?.Qxs?.includes('M_TongYeXiaoShouTongJi_List')
          "
        >
          <template #title>
            <el-icon :size="state.menuIconSize"><Comment /></el-icon>
            <span class="firstMenu">业务查询</span>
          </template>
          <!-- <el-menu-item index="8-1" class="secondMenu">
            部门销售统计
          </el-menu-item> -->
          <el-menu-item
            index="8-2"
            class="secondMenu"
            @click="onMenuClick('BaoBiaoXstj', '同业销售统计')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes(
                'M_TongYeXiaoShouTongJi_List'
              )
            "
          >
            同业销售统计
          </el-menu-item>
          <!-- <el-menu-item index="8-3" class="secondMenu">
            订单结算查询
          </el-menu-item>
          <el-menu-item index="8-4" class="secondMenu">
            供应商往来汇总
          </el-menu-item>
          <el-menu-item index="8-5" class="secondMenu">销售统计</el-menu-item> -->
        </el-sub-menu>
        <!-- <el-sub-menu index="9">
          <template #title>
            <el-icon :size="state.menuIconSize"><Tools /></el-icon>
            <span class="firstMenu">报表中心</span>
          </template>
          <el-menu-item index="9-1" class="secondMenu">
            公司利润统计表
          </el-menu-item>
          <el-menu-item index="9-2" class="secondMenu">
            产品销售排行榜
          </el-menu-item>
          <el-menu-item index="9-3" class="secondMenu">
            员工销售排行榜
          </el-menu-item>
          <el-menu-item index="9-4" class="secondMenu">
            同业销售排行榜
          </el-menu-item>
          <el-menu-item index="9-5" class="secondMenu">数据看板</el-menu-item>
        </el-sub-menu> -->
        <el-sub-menu
          index="10"
          v-if="
            $store.state.loginUser?.IsAdmin ||
            $store.state.loginUser?.Qxs?.includes('M_GongGao_List')
          "
        >
          <template #title>
            <el-icon :size="state.menuIconSize"><Menu /></el-icon>
            <span class="firstMenu">行政管理</span>
          </template>
          <el-menu-item
            index="10-1"
            class="secondMenu"
            @click="onMenuClick('GongGaoList', '通知公告')"
            >通知公告</el-menu-item
          >
          <!-- <el-menu-item index="10-2" class="secondMenu">投诉管理</el-menu-item>
          <el-menu-item index="10-3" class="secondMenu">电子合同</el-menu-item>
          <el-menu-item index="10-4" class="secondMenu">知识库</el-menu-item> -->
        </el-sub-menu>
        <el-sub-menu
          index="11"
          v-if="
            $store.state.loginUser?.IsAdmin ||
            $store.state.loginUser?.Qxs?.includes('M_YongHu_List') ||
            $store.state.loginUser?.Qxs?.includes('M_BuMen_List') ||
            $store.state.loginUser?.Qxs?.includes('M_JueSe_List') ||
            $store.state.loginUser?.Qxs?.includes('M_ZiDian_List')
          "
        >
          <template #title>
            <el-icon :size="state.menuIconSize"><Tools /></el-icon>
            <span class="firstMenu">系统管理</span>
          </template>
          <el-menu-item
            index="/bm"
            class="secondMenu"
            @click="onMenuClick('BuMenList', '部门管理')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_BuMen_List')
            "
          >
            部门管理
          </el-menu-item>
          <el-menu-item
            index="/js"
            class="secondMenu"
            @click="onMenuClick('JueSeList', '角色管理')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_JueSe_List')
            "
          >
            角色管理
          </el-menu-item>
          <el-menu-item
            index="/yh"
            class="secondMenu"
            @click="onMenuClick('YongHuList', '用户管理')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_YongHu_List')
            "
          >
            用户管理
          </el-menu-item>
          <!-- <el-menu-item
            index="11-4"
            class="secondMenu"
            @click="onMenuClick('RiZhiList', '登录日志')"
            >登录日志</el-menu-item
          > -->
          <el-menu-item
            index="/zd"
            class="secondMenu"
            @click="onMenuClick('ZiDianList', '基础数据')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_ZiDian_List')
            "
          >
            基础数据
          </el-menu-item>
          <el-menu-item
            index="/zh"
            class="secondMenu"
            @click="onMenuClick('ZhangHuList', '对公账户')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_ZhangHu_List')
            "
          >
            对公账户
          </el-menu-item>
          <el-menu-item
            index="/sms"
            class="secondMenu"
            @click="onMenuClick('SmsList', '短信模板')"
            v-if="
              $store.state.loginUser?.IsAdmin ||
              $store.state.loginUser?.Qxs?.includes('M_Sms_List')
            "
            >短信模板
          </el-menu-item>
        </el-sub-menu>
      </el-menu>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 10px;
        margin-top: 2px;
        padding-top: 10px;
        border-top: 1px solid #e2e2e2;
        cursor: pointer;
        border-right: 1px solid #e2e2e2;
      "
    >
      <el-icon
        size="20"
        color="#2f2f2f"
        @click="state.showMenu = !state.showMenu"
        ><Fold v-if="state.showMenu" /> <Expand v-else
      /></el-icon>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import store from "@/store";
export default {
  setup() {
    const state = reactive({
      showMenu: true,
      menuIconSize: 18,
    });
    onMounted(() => {});

    const onMenuClick = (path, title) => {
      store.commit("openMenu", { path, title, refresh: true });
    };

    return { state, onMenuClick };
  },
};
</script>
<style scoped>
.menu::-webkit-scrollbar {
  width: 0 !important;
}

.firstMenu {
  font-size: 12px;
}

.menu .secondMenu {
  font-size: 12px;
  /* color: rgb(26, 167, 38); */
  display: flex;
  align-items: center;
  padding-right: 0 !important;
  padding-left: 50px !important;
}

.el-popper .secondMenu {
  font-size: 12px;
  /* color: rgb(26, 167, 38); */
  display: flex;
  align-items: center;
  padding-right: 0 !important;
}
</style>
