<template>
  <div>
    <ul
      style="
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        justify-content: flex-end;
      "
      v-if="!props.search"
    >
      <el-button
        type="primary"
        icon="Plus"
        link
        @click="
          delete state.selectId;
          state.showAdd = true;
        "
      >
        添加
      </el-button>
    </ul>
    <el-table :data="state.tabData">
      <el-table-column type="index" label="#"> </el-table-column>
      <el-table-column prop="Lx" label="类型" :formatter="$tab_StatusFormat" />
      <el-table-column
        prop="Zffs"
        label="支付方式"
        :formatter="$tab_StatusFormat"
      />
      <el-table-column prop="Je" label="金额" :formatter="$tab_MoneyFormat" />
      <el-table-column
        prop="Zt"
        label="支付状态"
        :formatter="$tab_StatusFormat"
      />
      <el-table-column
        prop="Zfsj"
        label="支付时间"
        :formatter="$tab_TextFormat"
      />
      <el-table-column prop="Bz" label="备注" :formatter="$tab_TextFormat" />
      <el-table-column
        prop="Cjsj"
        sortable="Cjsj"
        label="创建信息"
        width="160"
        :formatter="(row) => $tab_GroupFormat(row.CjrXm, row.Cjsj)"
      />
      <el-table-column
        label="审核信息"
        width="160"
        :formatter="(row) => $tab_GroupFormat(row.ShrXm || '-', row.Shsj || '')"
      />
      <el-table-column
        prop="Shsm"
        label="审核说明"
        :formatter="$tab_TextFormat"
      />
      <el-table-column label="操作" fixed="right" width="180px" align="center">
        <template #default="{ row }">
          <template v-if="row.Zffs?.Key == 1">
            <el-button
              type="primary"
              :icon="Grid"
              link
              @click="onBuildQrCode(row)"
              v-if="row.Zt?.Key == 0"
              >二维码
            </el-button>
            <el-button
              type="danger"
              :icon="Delete"
              link
              @click="onDelete(row.Id)"
              v-if="row.Zt?.Key == 0"
            >
              删除
            </el-button>
          </template>
          <text v-else>-</text>
        </template>
      </el-table-column>
    </el-table>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showAdd"
        title="添加收款"
        @close="state.showAdd = false"
      >
        <c_LvYouDingDanShouKuanAdd
          @close="
            state.showAdd = false;
            onLoad();
            onRefreshDd();
          "
          :ddId="props.id"
          :id="state.selectId"
          :ddModel="props.model"
        ></c_LvYouDingDanShouKuanAdd>
      </c_popup>
    </transition>
    <div
      v-show="state.showQrCode"
      style="
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        z-index: 9999;
      "
      @click="state.showQrCode = false"
    >
      <div
        style="height: 80%; overflow: hidden; position: relative"
        @click.stop=""
      >
        <el-image
          :src="require('@/assets/images/code.png')"
          fit=""
          style="height: 100%"
        />
        <div class="shoukuan-code-box">
          <div ref="qrcodeDom" id="code" style="width: 50%"></div>
        </div>
        <div
          style="
            position: absolute;
            top: 63%;
            left: 10%;
            z-index: 99;
            width: 80%;
            height: 27%;
            padding: 0 10px;
            font-size: 13px;
            color: rgba(0, 0, 0, 0.8);
            overflow: hidden;
          "
        >
          <ul
            style="
              padding: 8px 0;
              border-bottom: 1px solid #f7f7f7;
              white-space: nowrap;
              overflow: hidden;
            "
          >
            收款单位：肥西县灵西文化科技有限公司
          </ul>
          <ul
            style="
              padding: 8px 0;
              border-bottom: 1px solid #f7f7f7;
              white-space: nowrap;
              overflow: hidden;
            "
          >
            收款金额：{{
              $priceFormat(state.selectItem?.Je)
            }}元
          </ul>
          <ul
            style="
              padding: 8px 0;
              border-bottom: 1px solid #f7f7f7;
              white-space: nowrap;
              overflow: hidden;
            "
          >
            收款业务订单号：{{
              state.selectItem?.Bh
            }}
          </ul>
          <ul
            style="
              padding: 8px 0;
              border-bottom: 1px solid #f7f7f7;
              white-space: nowrap;
              overflow: hidden;
            "
          >
            收款人：{{
              state.selectItem?.CjrXm
            }}
          </ul>
          <ul style="padding: 8px 0; white-space: nowrap; overflow: hidden">
            收款摘要：{{
              props.model?.Mc
            }}
          </ul>
        </div>
      </div>
      <!-- <div style="background-color: #fff; padding: 20px; width: 296px">
        <div ref="qrcodeDom" id="code"></div>
        <div style="margin-top: 10px; text-align: center">
          请使用微信或支付宝扫一扫~
        </div>
        <el-button
          type="info"
          @click="onClose()"
          style="margin-top: 10px; width: 100%"
          >关闭</el-button
        >
      </div> -->
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { lvyoudingdan_getsk, lvyoudingdan_delsk } from "@/http/api";
import { confirm_func } from "@/util/tFormat";
import QRCode from "qrcodejs2-fix";
export default {
  props: {
    id: Number,
    search: Boolean,
    model: Object,
  },
  setup(props, { emit }) {
    const qrcodeDom = ref(null);
    const state = reactive({});
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      lvyoudingdan_getsk(props.id).then((res) => {
        if (res.status == 200) {
          state.tabData = res.data;
        }
      });
    };

    const onDelete = (id) => {
      confirm_func(lvyoudingdan_delsk, id, onLoad);
    };

    //刷新订单
    const onRefreshDd = () => {
      emit("onLoad");
    };

    const onBuildQrCode = (row) => {
      state.selectItem = row;
      qrcodeDom.value.innerHTML = "";
      // const text = "http://192.168.3.46/#/lydd/pay/" + id;
      const text = "http://shwl.cs.ebsys.cn/#/lydd/pay/" + row.Id;
      state.showQrCode = true;
      new QRCode(qrcodeDom.value, {
        text,
      });
    };

    return {
      state,
      onDelete,
      props,
      onLoad,
      onRefreshDd,
      onBuildQrCode,
      qrcodeDom,
    };
  },
};
</script>
<style lang="less">
.shoukuan-code-box {
  position: absolute;
  top: 13.5%;
  left: 7%;
  z-index: 99;
  width: 86%;
  height: 33%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div > img {
    width: 100% !important;
  }
}
</style>
