<template>
  <div>
    <ul
      style="display: flex; align-items: center; margin-bottom: 10px"
      v-if="!props.search"
    >
      <el-upload
        v-model:file-list="state.files"
        multiple
        :auto-upload="false"
        :on-change="onFileChange"
        :on-exceed="onFileExeed"
        :show-file-list="false"
        :limit="1"
      >
        <el-button type="primary">上传文件</el-button>
      </el-upload>
      <!-- <el-button type="success" @click="onSubmit" style="margin-left: 10px">
        立即提交
      </el-button> -->
    </ul>
    <el-table :data="state.tabData">
      <el-table-column type="index" label="#" />
      <el-table-column prop="Mc" label="名称" />
      <el-table-column prop="Dz" label="路径" :formatter="$tab_TextFormat" />
      <el-table-column label="操作" fixed="right" width="180">
        <template #default="{ row }">
          <el-button
            type="success"
            :icon="Download"
            link
            @click="onDownLoad(row)"
            v-if="row.Id"
          >
            下载
          </el-button>
          <el-button
            type="danger"
            :icon="Delete"
            link
            @click="onDelete(row.Id)"
            v-if="!props.search"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import {
  lvyoudingdan_getfj,
  lvyoudingdan_addfj,
  lvyoudingdan_delfj,
} from "@/http/api";
import $http from "@/http/index";
import { ElMessage } from "element-plus";
import { confirm_func } from "@/util/tFormat";
export default {
  props: {
    id: Number,
    search: Boolean,
  },
  setup(props) {
    const state = reactive({});
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      lvyoudingdan_getfj(props.id).then((res) => {
        if (res.status == 200) {
          state.tabData = res.data;
        }
      });
    };

    const onDownLoad = (item) => {
      $http
        .get(item.Dz, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", item.Mc + "." + item.Hz);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          ElMessage.error("文件下载失败！");
        });
    };

    //选择文件不超限时触发，也就是选择一个文件时触发，如果不设置limit,该方法会因选择的选择数来触发次数，如一次性选择5个文件，该方法则被调用5次
    const onFileChange = (file) => {
      let formData = new FormData();
      formData.append("files", file.raw);
      lvyoudingdan_addfj(props.id, formData).then((res) => {
        if (res.status == 200) {
          ElMessage.success(res.data);
          onLoad();
        }
      });
    };

    //选择的文件超限时（超过limit的值）触发，limit设置为1的话，如果同时选择两个文件则触发，该方法每选择一次触发一次
    const onFileExeed = (files) => {
      let formData = new FormData();

      files.forEach((file) => {
        formData.append("files", file);
      });

      lvyoudingdan_addfj(props.id, formData).then((res) => {
        if (res.status == 200) {
          ElMessage.success(res.data);
          onLoad();
        }
      });
    };

    //删除
    const onDelete = (id) => {
      confirm_func(lvyoudingdan_delfj, id, onLoad);
    };

    return {
      state,
      onDownLoad,
      onDelete,
      onFileChange,
      onFileExeed,
      props,
    };
  },
};
</script>
<style scoped></style>
