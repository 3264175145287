<template>
  <el-table
    :data="state.tableData"
    v-loading="!Array.isArray(state.tableData)"
    element-loading-background="rgba(0, 0, 0, 0.2)"
  >
    <el-table-column label="#" type="index"></el-table-column>
    <el-table-column prop="Xm" label="姓名"></el-table-column>
    <el-table-column prop="Sjhm" label="手机号码"></el-table-column>
    <el-table-column
      prop="Zjlx"
      label="证件类型"
      :formatter="$tab_TextFormat"
    ></el-table-column>
    <el-table-column prop="Zjh" label="证件号"></el-table-column>
    <el-table-column
      prop="Xb"
      label="性别"
      :formatter="$tab_StatusFormat"
    ></el-table-column>
    <el-table-column prop="Csrq" label="出生日期"></el-table-column>
    <el-table-column prop="Nl" label="年龄"></el-table-column>
    <el-table-column
      prop="Bz"
      label="备注"
      :formatter="$tab_TextFormat"
    ></el-table-column>
  </el-table>
</template>

<script>
import { onMounted, reactive } from "vue";
import { shangpindingdan_getyk } from "@/http/api";
export default {
  props: {
    ddId: Number,
  },
  setup(props) {
    const state = reactive({});
    onMounted(() => {
      shangpindingdan_getyk(props.ddId).then((res) => {
        if (res.status == 200) state.tableData = res.data;
      });
    });
    return { state };
  },
};
</script>
<style scoped></style>
