<template>
  <div class="listBox">
    <ul class="sBox">
      <el-input
        v-model="state.params.Params.Key"
        style="width: 15%"
        placeholder="关键字"
        clearable
        @clear="
          delete state.params.Params.Key;
          onLoad();
        "
      ></el-input>
      <el-radio-group
        v-model="state.params.Params.Zt"
        style="margin-left: 10px"
        @change="onLoad()"
      >
        <el-radio-button>全部</el-radio-button>
        <el-radio-button
          :label="item.Key"
          v-for="item in state.ztData"
          :key="item"
        >
          <text
            :style="{
              color: state.params.Params.Zt == item.Key ? '' : item.Color,
            }"
          >
            {{ item.Text }}</text
          >
        </el-radio-button>
      </el-radio-group>
      <el-dropdown
        split-button
        @click="onLoad"
        type="primary"
        style="margin: 0 10px"
      >
        查询
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item :icon="RefreshLeft" @click="onReset()">
              重置
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-button type="success" :icon="Download" @click="onExport">
        导出
      </el-button>
    </ul>
    <ul class="tBox">
      <el-table
        :data="state.tableData"
        v-loading="!Array.isArray(state.tableData)"
        element-loading-background="rgba(0, 0, 0, 0.2)"
        :default-sort="{
          prop: state.params.Order,
          order: state.params.Asc == false ? 'descending' : 'ascending',
        }"
        @sort-change="onSortChange"
      >
        <el-table-column
          fixed
          label="#"
          width="50"
          type="index"
          :index="
            (index) =>
              (state.params.PageIndex - 1) * state.params.PageSize + index + 1
          "
          align="center"
        />
        <el-table-column
          sortable="Zt"
          label="状态/编号"
          width="160"
          :formatter="
            (row) => $tab_GroupFormat(row.Zt?.Text, row.Bh, row.Zt?.Color)
          "
        />
        <el-table-column
          prop="Ly"
          sortable="Ly"
          label="来源"
          :formatter="$tab_StatusFormat"
          min-width="100"
        />
        <el-table-column
          prop="KhMc"
          sortable="KhMc"
          label="客户"
          :formatter="
            (row) => $tab_GroupFormat(row.KhMc, row.KhLxr + ' ' + row.KhSjhm)
          "
          :show-overflow-tooltip="true"
          min-width="140"
        />
        <el-table-column
          prop="Cjsj"
          sortable="Cjsj"
          label="下单时间"
          align="center"
          width="170"
        />
        <el-table-column prop="Sl" label="商品数" align="center" />
        <el-table-column prop="Ycps" label="已出票" align="center" />
        <el-table-column
          sortable="Xsj"
          label="结算价/销售价"
          width="160"
          :formatter="
            (row) =>
              $tab_GroupFormat($priceFormat(row.Jsj), $priceFormat(row.Xsj))
          "
        />
        <el-table-column
          sortable="Ysje"
          label="已收/未收"
          width="140"
          :formatter="
            (row) =>
              $tab_GroupFormat($priceFormat(row.Ysje), $priceFormat(row.Khqk))
          "
        />
        <el-table-column
          sortable="Yfje"
          label="已付/未付"
          width="140"
          :formatter="
            (row) =>
              $tab_GroupFormat($priceFormat(row.Yfje), $priceFormat(row.Jsqk))
          "
        />
        <el-table-column
          prop="Bz"
          label="订单备注"
          :formatter="$tab_TextFormat"
          min-width="200"
          show-overflow-tooltip
        />
        <el-table-column
          label="操作"
          width="100"
          align="center"
          fixed="right"
          className="tabBtn"
        >
          <template #default="{ row }">
            <el-button
              type="primary"
              :icon="Operation"
              link
              @click="
                $store.commit('openMenu', {
                  path: 'ShangPinDingDanDetail',
                  title: '商品订单详情',
                  data: { id: row.Id },
                  refresh: true,
                })
              "
              v-if="
                $store.state.loginUser?.IsAdmin ||
                $store.state.loginUser?.Qxs?.includes(
                  'B_ShangPinDingDan_List_管理'
                )
              "
              >管理
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </ul>
    <c_page
      :total="state.totalCount"
      :pageIndex="state.params.PageIndex"
      @onLoad="
        (pageIndex) => {
          state.params.PageIndex = pageIndex;
          onLoad();
        }
      "
    >
    </c_page>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import {
  shangpindingdan_list,
  shangpindingdan_getzt,
  shangpindingdan_export,
} from "@/http/api";

class InitFormData {
  params = {
    PageSize: 20,
    PageIndex: 1,
    Params: {},
    Order: "Cjsj",
    Asc: false,
  };
}

export default {
  components: {},
  setup() {
    const state = reactive(new InitFormData());
    onMounted(() => {
      shangpindingdan_getzt().then((res) => {
        if (res.status == 200) state.ztData = res.data;
      });

      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      shangpindingdan_list(state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    //排序
    const onSortChange = ({ column }) => {
      if (column.order) {
        state.params.Order = column.sortable;
        state.params.Asc = column.order === "ascending";
      } else {
        const { params } = new InitFormData();
        state.params.Order = params.Order;
        state.params.Asc = params.Asc;
      }
      onLoad();
    };

    //重置查询条件
    const onReset = () => {
      Object.assign(state, new InitFormData());
      onLoad();
    };

    const onExport = () => {
      shangpindingdan_export(state.params).then((res) => {
        if (res.status == 200) {
          //通过后台返回 的word文件流设置文件名并下载
          var blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }); //application/vnd.openxmlformats-officedocument.wordprocessingml.document这里表示doc类型
          var downloadElement = document.createElement("a");
          var href = window.URL.createObjectURL(blob); //创建下载的链接
          downloadElement.href = href;
          downloadElement.download = "商品订单表.xlsx"; //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象
        }
      });
    };

    return {
      state,
      onLoad,
      onSortChange,
      onReset,
      onExport,
    };
  },
};
</script>
<style lang="less" scoped>
.drawerCss {
  width: 40vw !important;
}

.drawerCss > div {
  overflow: hidden;
  padding: 0 !important;
}
</style>
