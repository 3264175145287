<template>
  <div class="detailBox">
    <ul class="detailContent">
      <div style="margin-bottom: 10px; display: flex; align-items: center">
        <li style="flex: 1">
          <el-button type="warning" icon="Download" @click="onDownLoadYkmb"
            >模板下载</el-button
          >
        </li>
        <el-upload
          action="#"
          :show-file-list="false"
          ref="uploadDom"
          accept=".xlsx"
          :auto-upload="false"
          :on-change="onChangeFile"
        >
          <template #default>
            <el-button
              type="success"
              icon="UploadFilled"
              style="margin: 0 10px"
            >
              导入名单
            </el-button>
          </template>
        </el-upload>
        <el-button type="primary" icon="Check" @click="onSubmit">
          提交保存
        </el-button>
        <el-button type="danger" icon="Check" @click="onClear">
          一键清空
        </el-button>
      </div>
      <el-table :data="state.tabData" :key="state.key">
        <el-table-column type="index" label="#"> </el-table-column>
        <el-table-column label="游客姓名">
          <template #default="{ row }">
            <text v-if="!row.IsEdit">{{ row.Xm || "-" }}</text>
            <!-- <el-input v-else v-model="row.Xm"></el-input> -->
            <c_Input
              v-else
              :value="row.Xm"
              @Value-Change="row.Xm = $event"
              placeholder="--游客姓名--"
            />
          </template>
        </el-table-column>
        <el-table-column label="手机号码">
          <template #default="{ row }">
            <!-- <el-input v-model="row.Sjhm"></el-input> -->
            <text v-if="!row.IsEdit">{{ row.Sjhm || "-" }}</text>
            <c_Input
              v-else
              :value="row.Sjhm"
              @Value-Change="row.Sjhm = $event"
              placeholder="--手机号码--"
            />
          </template>
        </el-table-column>
        <el-table-column label="证件类型">
          <template #default="{ row }">
            <text v-if="!row.IsEdit">{{ row.Zjlx || "-" }}</text>
            <el-select
              v-else
              v-model="row.Zjlx"
              placeholder="--请选择证件类型--"
            >
              <el-option
                v-for="item in state.zjlxData"
                :key="item"
                :label="item.Text"
                :value="item.Text"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="证件号" min-width="140">
          <template #default="{ row }">
            <text v-if="!row.IsEdit">{{ row.Zjh || "-" }}</text>
            <el-input
              v-else
              v-model="row.Zjh"
              placeholder="--证件号--"
              @change="onZjhChange(row)"
            />
          </template>
        </el-table-column>
        <el-table-column label="性别" width="100">
          <template #default="{ row }">
            <template v-if="!row.IsEdit">
              <text v-if="row.Xb == 1" style="color: #1e90ff">男</text>
              <text v-else-if="row.Xb == 2" style="color: #f00">女</text>
              <text v-else>-</text>
            </template>
            <el-select v-else v-model="row.Xb" placeholder="--请选择性别--">
              <el-option label="男" :value="1" />
              <el-option label="女" :value="2" />
            </el-select>
            <!-- 
            <c_Select
              v-else
              :value="row.Xb"
              :dataArr="[
                { Id: 1, Mc: '男' },
                { Id: 2, Mc: '女' },
              ]"
              valueKey="Id"
              @Value-Change="row.Xb = $event"
              placeholder="--请选择性别--"
              :showRefresh="false"
            /> -->
          </template>
        </el-table-column>
        <el-table-column label="出生日期" width="160">
          <template #default="{ row }">
            <text v-if="!row.IsEdit">{{ row.Csrq || "-" }}</text>
            <el-date-picker
              v-else
              v-model="row.Csrq"
              type="date"
              placeholder="--出生日期--"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              style="width: 100%"
            />
          </template>
        </el-table-column>
        <el-table-column label="备注">
          <template #default="{ row }">
            <text v-if="!row.IsEdit">{{ row.Bz || "-" }}</text>
            <c_Input
              v-else
              :value="row.Bz"
              @Value-Change="row.Bz = $event"
              placeholder="--备注--"
            />
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="{ row }">
            <el-button
              type="primary"
              :icon="EditPen"
              link
              @click="onShowEdit(row)"
              v-if="!row.IsEdit"
              >编辑
            </el-button>
            <el-button
              type="success"
              :icon="Select"
              link
              @click="row.IsEdit = false"
              v-if="row.IsEdit"
              >暂存
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </ul>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import {
  lvyoudingdan_getyk,
  lvyoudingdan_edityk,
  home_getzjlx,
  lvyoudingdan_importyk,
  lvyoudingdan_clearyk,
} from "@/http/api";
import { ElMessageBox, ElMessage, dayjs } from "element-plus";
import { cardTypeArr } from "@/util/enum";
import { post_response } from "@/util/tFormat";

import c_Input from "./YouKeComponents/Input.vue";

export default {
  components: { c_Input },
  props: {
    ddId: Number,
  },
  setup(props, { emit }) {
    const formDom = ref(null);
    const state = reactive({
      model: {
        Xb: 1,
        Zjlx: 1,
      },
      jsData: [],
      tabData: [],
      zjlxData: [],
    });
    onMounted(() => {
      onLoadZjlx();

      onLoad();
    });

    const onLoad = () => {
      if (props.ddId) {
        lvyoudingdan_getyk(props.ddId).then((res) => {
          if (res.status == 200) {
            state.tabData = res.data;
          }
        });
      }
    };

    const onLoadZjlx = () => {
      home_getzjlx().then((res) => {
        if (res.status == 200) state.zjlxData = res.data;
      });
    };

    //下载模板
    const onDownLoadYkmb = () => {
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.href = "./static/名单表模板.xlsx";
      a.download = "名单表模板.xlsx";
      a.style.display = "none";
      a.click();
      document.body.removeChild(a);
    };

    //出生日期有效性
    const onJudgeCsrq = (value) => {
      if (dayjs(value) < dayjs()) {
        return false;
      } else return true;
    };

    const onSubmit = () => {
      lvyoudingdan_edityk(props.ddId, state.tabData).then((res) => {
        post_response(res, emit);
      });
    };

    const onChangeFile = (file) => {
      let formData = new FormData();
      //把接口需要的参数带进去
      formData.append("file", file.raw);
      lvyoudingdan_importyk(props.ddId, formData).then((res) => {
        if (res.status == 200) {
          console.log(res.data);

          state.tabData = res.data;
          state.key = Math.random();
        }
      });
    };

    const onShowEdit = (row) => {
      if (!row.Zjlx) row.Zjlx = "身份证";
      row.IsEdit = true;
    };

    //证件号改变事件
    const onZjhChange = (row) => {
      if (!row.Csrq) {
        row.Csrq =
          row.Zjh.substring(6, 10) +
          "-" +
          row.Zjh.substring(10, 12) +
          "-" +
          row.Zjh.substring(12, 14);
      }

      if (!row.Xb) {
        row.Xb = parseInt(row.Zjh.substr(16, 1)) % 2 == 1 ? 1 : 2;
      }
    };

    const onClear = () => {
      ElMessageBox.confirm(
        "您确定清空该订单所有游客信息吗？<strong style='color:#fff'>(清空后不可恢复，请慎重操作)</strong>",
        "Warning",
        {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
          dangerouslyUseHTMLString: true,
        }
      ).then(() => {
        lvyoudingdan_clearyk(props.ddId).then((res) => {
          if (res.status == 200) {
            ElMessage.success(res.data);
            onLoad();
          }
        });
      });
    };

    return {
      state,
      formDom,
      onSubmit,
      props,
      cardTypeArr,
      onJudgeCsrq,
      onDownLoadYkmb,
      onLoadZjlx,
      onChangeFile,
      onClear,
      onZjhChange,
      onShowEdit,
    };
  },
};
</script>
<style scoped></style>
