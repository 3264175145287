<template>
  <div class="detailBox">
    <ul class="detailContent">
      <el-form
        :model="state.model"
        label-width="100px"
        style="width: 100%"
        :scroll-to-error="true"
        ref="formDom"
        class="sp_tc_complie_form"
      >
        <el-form-item
          label="状态"
          prop="IsQy"
          :rules="[{ required: true, message: '请选择状态', trigger: 'blur' }]"
        >
          <el-switch v-model="state.model.IsQy" />
        </el-form-item>
        <el-form-item
          label="套餐名称"
          prop="Mc"
          :rules="[
            { required: true, message: '请输入套餐名称', trigger: 'blur' },
          ]"
        >
          <el-input v-model="state.model.Mc"></el-input>
        </el-form-item>
        <el-form-item label="套餐说明" prop="Sm">
          <el-input
            type="textarea"
            v-model="state.model.Sm"
            :autosize="{ minRows: 5 }"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="剩余库存"
          prop="Sykc"
          :rules="[
            { required: true, message: '请填写库存数量', trigger: 'blur' },
          ]"
          style="position: relative"
        >
          <el-input v-model="state.model.Sykc" type="number"></el-input>
          <strong style="position: absolute; left: 31vw; color: #f00"
            >-1表示不限制库存</strong
          >
        </el-form-item>
        <el-form-item
          label="单位"
          prop="Dw"
          :rules="[{ required: true, message: '请选择单位', trigger: 'blur' }]"
        >
          <div style="display: flex; align-items: center; width: 100%">
            <el-select
              v-model="state.model.Dw"
              placeholder="--请选择单位--"
              :filter-method="onDwFilter"
              :disabled="!Array.isArray(state.dwData)"
            >
              <el-option
                v-for="item in state.dwData"
                :key="item.Mc"
                :label="item.Mc"
                :value="item.Mc"
              />
              <template #footer>
                <el-button size="small" @click="onLoadDw()"> 刷新 </el-button>
              </template>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="是否需要名单" prop="IsMd">
          <el-switch v-model="state.model.IsMd" />
        </el-form-item>
        <el-form-item
          label="名单数"
          prop="Mds"
          :rules="[
            {
              required: state.model.IsMd,
              validator: validateMds,
              trigger: 'blur',
              title: '比例',
            },
          ]"
        >
          <el-input
            v-model="state.model.Mds"
            type="number"
            :disabled="!state.model.IsMd"
          ></el-input>
        </el-form-item>
      </el-form>
    </ul>
    <ul class="detailBtn">
      <el-button type="primary" @click="onSubmit()">提交</el-button>
    </ul>
  </div>
</template>

<script>
import { onMounted, reactive, ref, getCurrentInstance } from "vue";
import {
  zidian_select,
  shangpintaocan_detail,
  shangpintaocan_add,
  shangpintaocan_edit,
} from "@/http/api";

export default {
  props: {
    id: Number,
    spId: Number,
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const formDom = ref(null);
    const state = reactive({
      model: { IsQy: true, Sykc: -1 },
    });
    onMounted(() => {
      onLoadDw();

      if (props.id) {
        shangpintaocan_detail(props.id).then((res) => {
          if (res.status == 200) state.model = res.data;
        });
      }
    });

    const onLoadDw = () => {
      delete state.dwData;
      zidian_select("单位").then((res) => {
        if (res.status == 200) {
          state.dwData = res.data;
          state.dwSource = res.data;
        }
      });
    };

    //单位下拉框自定义筛选
    const onDwFilter = (query) => {
      state.dwData = state.dwSource.filter(
        (w) =>
          w.Py?.toUpperCase().includes(query.toUpperCase()) ||
          w.Sp?.toUpperCase().includes(query.toUpperCase()) ||
          w.Mc?.includes(query.toUpperCase())
      );
    };

    const validateMds = (rule, value, callback) => {
      if (state.model.IsMd == true && (value == null || value <= 0))
        callback(new Error("名单数不能小于0"));

      return callback();
    };

    const onSubmit = () => {
      formDom.value.validate((valid) => {
        if (valid) {
          if (props.id) {
            shangpintaocan_edit(props.id, state.model).then((res) => {
              proxy.$post_response(res, emit);
            });
          } else {
            shangpintaocan_add(props.spId, state.model).then((res) => {
              proxy.$post_response(res, emit);
            });
          }
        }
      });
    };

    return {
      state,
      formDom,
      onSubmit,
      onDwFilter,
      props,
      validateMds,
      onLoadDw,
    };
  },
};
</script>
<style scoped lang="less">
.sp_tc_complie_form {
  .el-select,
  .el-input,
  .el-textarea {
    width: 30vw;
  }
}
</style>
