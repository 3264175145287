<template>
  <div class="detailBox" style="position: relative">
    <ul class="detailContent" style="display: flex">
      <el-form
        :model="state.model"
        label-width="100px"
        :scroll-to-error="true"
        ref="domForm"
        :rules="rules"
        size="large"
        style="flex: 1"
        class="dybz-add-form"
      >
        <el-form-item label="导游姓名">
          {{ state.model.DyXm }}
        </el-form-item>
        <el-form-item label="联系电话">
          {{ state.model.DySjhm }}
        </el-form-item>
        <el-form-item label="证件号">
          {{ state.model.DyZjh }}
        </el-form-item>
        <el-form-item label="发放合计">
          <c_money :value="state.model.Bzje"></c_money>
        </el-form-item>
        <el-form-item
          label="业务审批人"
          prop="YwrId"
          :rules="[
            {
              required: true,
              message: '请选择业务审批人',
              trigger: 'blur',
            },
          ]"
        >
          <div style="display: flex; width: 100%; margin-bottom: 10px">
            <el-input
              v-model="state.model.YwrXm"
              style="width: 200px; margin-right: 10px"
            /><el-button type="warning" @click="onSelectYongHu(1)"
              >选择</el-button
            >
          </div>
        </el-form-item>
        <el-form-item
          label="财务审批人"
          prop="CwrId"
          :rules="[
            {
              required: true,
              message: '请选择财务审批人',
              trigger: 'blur',
            },
          ]"
        >
          <div style="display: flex; width: 100%; margin-bottom: 10px">
            <el-input
              v-model="state.model.CwrXm"
              style="width: 200px; margin-right: 10px"
            /><el-button type="warning" @click="onSelectYongHu(2)"
              >选择</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="申请说明">
          <el-input
            type="textarea"
            v-model="state.model.Bz"
            :autosize="{ minRows: 5 }"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="flex: 1; margin-left: 20px">
        <ul style="font-size: 14px; font-weight: 600">
          带团信息
        </ul>
        <div
          style="
            background-color: #f6f6f6;
            display: flex;
            flex-direction: column;
            padding: 10px;
            padding-top: 0;
            margin-top: 10px;
            border-radius: 8px;
          "
        >
          <div
            style="
              margin-top: 10px;
              background-color: #fff;
              border-radius: 8px;
              padding: 10px 20px;
              position: relative;
            "
            v-for="item in state.dypqs"
            :key="item"
          >
            <li
              style="
                position: absolute;
                top: 0;
                right: 0;
                padding: 10px 20px;
                color: #fff;
                border-top-right-radius: 8px;
                border-bottom-left-radius: 8px;
              "
              :style="{ background: item.DdZt?.Color }"
            >
              {{ item.DdZt?.Text }}
            </li>
            <ul style="display: flex; position: relative">
              <li style="display: flex; align-items: center">
                <text style="font-size: 16px; font-weight: 600">{{
                  item.DdMc
                }}</text>
                <text style="font-size: 12px; margin-left: 5px">{{
                  item.DdBh
                }}</text>
                <text
                  style="font-size: 12px; font-weight: 600; margin-left: 100px"
                  >{{ item.DdRq }}</text
                >
              </li>
            </ul>
            <ul style="display: flex; align-items: center; margin: 20px 0">
              <li style="flex: 1">
                <text style="color: #a2a2a2">服务日期：</text>{{ item.Fwrq }}
              </li>
              <li style="display: flex">
                <text style="color: #a2a2a2">补助：</text
                ><c_money :value="item.Bzje"></c_money>
              </li>
            </ul>
            <ul>
              <text style="color: #a2a2a2">带团说明：</text>
              {{
                item.Bzsm || "-"
              }}
            </ul>
          </div>
        </div>
      </div>
    </ul>
    <ul
      class="detailBtn"
      style="justify-content: flex-end; padding-right: 20px"
    >
      <el-button type="primary" @click="onSubmit">提交申请</el-button>
    </ul>
    <transition name="el-zoom-in-center">
      <c_popup
        v-if="state.showYongHu"
        :title="state.title"
        @close="state.showYongHu = false"
      >
        <c_LvYouDingDanSelectUser
          @close="onCloseYongHu"
          :id="state.selectId"
        ></c_LvYouDingDanSelectUser>
      </c_popup>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { daoyoubuzhu_add } from "@/http/api";
import { ElMessage } from "element-plus";

export default {
  props: {
    data: Object,
  },
  setup(props, { emit }) {
    const state = reactive({
      model: {
        IsQy: true,
        Xb: 1,
      },
      item: {},
    });
    onMounted(() => {
      const { model, dypqs } = props.data;
      state.model = model;
      state.dypqs = dypqs;
      console.log(props);
    });

    const onSelectYongHu = (type) => {
      if (type == 1) {
        state.title = "选择业务审批人";
        state.selectId = state.model.YwrId;
      } else if (type == 2) {
        state.title = "选择财务审批人";
        state.selectId = state.model.CwrId;
      }
      state.showYongHu = true;
    };

    const onCloseYongHu = (item) => {
      if (state.title == "选择业务审批人") {
        state.model.YwrId = item.Id;
        state.model.YwrXm = item.Xm;
      } else if (state.title == "选择财务审批人") {
        state.model.CwrId = item.Id;
        state.model.CwrXm = item.Xm;
      }
      state.showYongHu = false;
    };

    const onSubmit = () => {
      state.model.DypqIds = props.data.ids;
      daoyoubuzhu_add(state.model).then((res) => {
        ElMessage({
          message: res.data,
          showClose: true,
          dangerouslyUseHTMLString: true,
          type: res.status ? "success" : "error",
        });
        if (res.status == 200) {
          setTimeout(() => {
            emit("close", true);
          }, 500);
        }
      });
    };

    return { state, onSubmit, props, onSelectYongHu, onCloseYongHu };
  },
};
</script>
<style lang="less">
.dybz-add-form {
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
